import { BaseRepository } from '@/api/baseRepository';
import { replaceEnvPath } from '@/shared/utils';
import { convertQueryToGetParams, convertQueryToJsonRpc } from '@/shared/api';
import enums from "@/config/enums";

const BACKEND_ENDPOINTS = {
  // User / Tags
  TAGS: {
    url: '/v1/me/tags',
    filter: {
      user_id: { type: enums.API_FILTER_TYPES.EQ_INT },
      trigger_type_id: { type: enums.API_FILTER_TYPES.IN_INT },
    },
  },
  TAG: { url: '/v1/me/tags/{tag_id}' },
};

// Get the list of tags
export function getTagsList(data = { q: '' }) {
  const endpoint = BACKEND_ENDPOINTS.TAGS;
  const params = convertQueryToGetParams(endpoint, data);
  return BaseRepository.get(endpoint.url, {
    params: {
      q: '',
      ...params,
    },
    useIdmp: false,
  });
}

// Create new Tag
export function postTag(data) {
  const path = BACKEND_ENDPOINTS.TAGS.url;
  return BaseRepository.post(path, data);
}

// Get Tag
export function getTag(tag_id) {
  const path = replaceEnvPath(BACKEND_ENDPOINTS.TAG.url, { tag_id });
  return BaseRepository.get(path, {
    useIdmp: false,
  });
}

// Update Tag
export function patchTag(tag_id, data = {}) {
  const path = replaceEnvPath(BACKEND_ENDPOINTS.TAG.url, { tag_id });
  return BaseRepository.patch(path, data);
}

// Delete Tag
export function deleteTag(tag_id) {
  const path = replaceEnvPath(BACKEND_ENDPOINTS.TAG.url, { tag_id });
  return BaseRepository.delete(path);
}

export const UserTagsRepository = {
  getTagsList,
  postTag,
  getTag,
  patchTag,
  deleteTag,
};

export default UserTagsRepository;
