import { BaseRepository } from '@/api/baseRepository';
import { convertQueryToGetParams } from "@/shared/api";
import { replaceEnvPath } from "@/shared/utils";
import { CLIENT_ROLE_TYPES } from "@/config/enums";
import { getUser } from "@/api/repositories/userRepository";

const BACKEND_ENDPOINTS = {
  // User / Settings
  // USER_SETTINGS: { url: '/v1/users/{user_id}/settings' },
  USER_SETTINGS: { url: '/v2/me/settings' },
  USER_STATUS: { url: '/v1/me/status' },
  USER_CONTENT_STATS: { url: '/v1/me/content_stats' },
  USER_CHANGE_PASSWORD: { url: '/v1/me/change_password' },
};

// Returns user setting for UI
export async function getUserSettings(payload = {}) {
  const { opid } = payload;
  const path = BACKEND_ENDPOINTS.USER_SETTINGS.url;
  return BaseRepository.get(path, {
    useIdmp: false,
    opid,
  });
}

// Returns user profile status
export function getUserStatus(payload = {}) {
  const { opid } = payload;
  const path = BACKEND_ENDPOINTS.USER_STATUS.url;
  return BaseRepository.get(path, {
    useIdmp: false,
    opid,
  });
}

// Returns user content stats
export function getUserContentStats(data = {}) {
  const endpoint = BACKEND_ENDPOINTS.USER_CONTENT_STATS;
  const params = convertQueryToGetParams(endpoint, data);
  return BaseRepository.get(endpoint.url, {
    params,
    useIdmp: false,
  });
}

// Change user password
export function changeUserPassword(data) {
  const path = BACKEND_ENDPOINTS.USER_CHANGE_PASSWORD.url;
  return BaseRepository.put(path, data);
}
