import { BaseRepository } from '@/api/baseRepository';
import { replaceEnvPath } from '@/shared/utils';
import { convertQueryToGetParams, convertQueryToJsonRpc } from '@/shared/api';
import { API_FILTER_TYPES } from "@/config/enums";

const BACKEND_ENDPOINTS = {
  API_CLIENTS: { url: '/v2/api_clients' },
  API_CLIENT: { url: '/v2/api_clients/{ac_id}' },
  API_CLIENT_SECRETS: { url: '/v1/api_client_secrets' },
  API_CLIENT_SECRET: { url: '/v1/api_client_secrets/{api_client_id}' },
  API_CLIENTS_QUERY: {
    url: '/v2/api_clients/query',
    filter: {
      id: { type: API_FILTER_TYPES.IN_INT },
      client_id: { type: API_FILTER_TYPES.IN_INT },
      subscription_id: { type: API_FILTER_TYPES.IN_INT },
      is_suspended: { type: API_FILTER_TYPES.EQ_BOOLEAN },
      created_at: { type: API_FILTER_TYPES.GTE_LTE_DATE_TIME },
      modified_at: { type: API_FILTER_TYPES.GTE_LTE_DATE_TIME },
    },
  },
};

// Get API clients list
export async function getApiClients(data = {}) {
  const endpoint = BACKEND_ENDPOINTS.API_CLIENTS_QUERY;
  const query = convertQueryToJsonRpc(endpoint, {
    client: 0,
    subscription: 0,
    ...data,
  });
  return BaseRepository.post(endpoint.url, query, {
    useIdmp: false,
    query: true,
  });
}

// Add the API Client
export function postApiClient(data = {}) {
  const path = BACKEND_ENDPOINTS.API_CLIENTS.url;
  return BaseRepository.post(path, data);
}

// Get the API Client by id
export async function getApiClient(ac_id, data = {}) {
  const endpoint = BACKEND_ENDPOINTS.API_CLIENT;
  const path = replaceEnvPath(endpoint.url, { ac_id });
  const params = convertQueryToGetParams(endpoint, {
    id_type: 'sharp',
    client: 0,
    subscription: 0,
    ...data,
  });

  return BaseRepository.get(path, {
    params,
    useIdmp: false,
  });
}

// Update API Client
export function putApiClient(ac_id, data = {}) {
  const path = replaceEnvPath(BACKEND_ENDPOINTS.API_CLIENT.url, { ac_id });
  return BaseRepository.put(path, data);
}

// Update Api Client
export function patchApiClient(ac_id, data = {}) {
  const path = replaceEnvPath(BACKEND_ENDPOINTS.API_CLIENT.url, { ac_id })
  return BaseRepository.patch(path, {
    ...data,
  }, {
    params: {
      id_type: 'sharp',
    },
  });
}

// Delete Api Client
export function deleteApiClient(ac_id) {
  const path = replaceEnvPath(BACKEND_ENDPOINTS.API_CLIENT.url, { ac_id });
  return BaseRepository.delete(path);
}

// Add the API Client Secret
export function postApiClientSecret(data = {}) {
  const path = BACKEND_ENDPOINTS.API_CLIENT_SECRETS.url;
  return BaseRepository.post(path, data);
}

// Get the API Client Secrets by api_client_id
export function getApiClientSecrets(api_client_id) {
  const path = replaceEnvPath(BACKEND_ENDPOINTS.API_CLIENT_SECRET.url, { api_client_id });
  return BaseRepository.get(path, {
    useIdmp: false,
  });
}
