import { BaseRepository } from '@/api/baseRepository';
import { replaceEnvPath } from '@/shared/utils';
import { convertQueryToGetParams, convertQueryToJsonRpc } from '@/shared/api';
import enums from "@/config/enums";

const BACKEND_ENDPOINTS = {
  VORTAL_CONNECT_CONTACTS: {
    url: '/v1/vortal_connect/contacts',
    filter: {
      id: { type: enums.API_FILTER_TYPES.IN_INT },
      vortal_user_code: { type: enums.API_FILTER_TYPES.EQ_STRING },
      country_id: { type: enums.API_FILTER_TYPES.IN_INT },
      email: { type: enums.API_FILTER_TYPES.EQ_STRING },
      banned: { type: enums.API_FILTER_TYPES.EQ_BOOLEAN_NUMBER },
      unsubscribed: { type: enums.API_FILTER_TYPES.EQ_BOOLEAN_NUMBER },
      vortal_company_id: { type: enums.API_FILTER_TYPES.IN_INT },
      created_at: { type: enums.API_FILTER_TYPES.GTE_LTE_DATE_TIME },
      modified_at: { type: enums.API_FILTER_TYPES.GTE_LTE_DATE_TIME },
    },
  },
  VORTAL_CONNECT_CONTACT: { url: '/v1/vortal_connect/contacts/{contact_id}' },
  VORTAL_CONNECT_COMPANIES: {
    url: '/v1/vortal_connect/companies',
    filter: {
      id: { type: enums.API_FILTER_TYPES.IN_INT },
      vortal_company_code: { type: enums.API_FILTER_TYPES.EQ_STRING },
      country_id: { type: enums.API_FILTER_TYPES.IN_INT },
      vortal_company_identifier_type: { type: enums.API_FILTER_TYPES.IN_INT },
      company_identifier: { type: enums.API_FILTER_TYPES.EQ_STRING },
      created_at: { type: enums.API_FILTER_TYPES.GTE_LTE_DATE_TIME },
      modified_at: { type: enums.API_FILTER_TYPES.GTE_LTE_DATE_TIME },
    },
  },
  VORTAL_CONNECT_COMPANY: { url: '/v1/vortal_connect/companies/{company_id}' },
  VORTAL_CONNECT_PROJECTS: {
    url: '/v1/vortal_connect/projects',
    filter: {
      id: { type: enums.API_FILTER_TYPES.IN_INT },
      vortal_operation_uid: { type: enums.API_FILTER_TYPES.EQ_STRING },
      country_id: { type: enums.API_FILTER_TYPES.IN_INT },
      vortal_company_identifier_type: { type: enums.API_FILTER_TYPES.IN_INT },
      company_identifier: { type: enums.API_FILTER_TYPES.EQ_STRING },
      created_at: { type: enums.API_FILTER_TYPES.GTE_LTE_DATE_TIME },
      modified_at: { type: enums.API_FILTER_TYPES.GTE_LTE_DATE_TIME },
    },
  },
  VORTAL_CONNECT_NOTIFICATION_EMAILS: {
    url: '/v1/vortal_connect/notification_emails',
    filter: {
      id: { type: enums.API_FILTER_TYPES.IN_INT },
      email: { type: enums.API_FILTER_TYPES.EQ_STRING },
      country_id: { type: enums.API_FILTER_TYPES.IN_INT },
      created_at: { type: enums.API_FILTER_TYPES.GTE_LTE_DATE_TIME },
      modified_at: { type: enums.API_FILTER_TYPES.GTE_LTE_DATE_TIME },
    },
  },
  VORTAL_CONNECT_NOTIFICATION_EMAIL: { url: '/v1/vortal_connect/notification_emails/{notification_email_id}' },
  VORTAL_CONNECT_LOG: {
    url: '/v1/vortal_connect/log',
    filter: {
      id: { type: enums.API_FILTER_TYPES.IN_INT },
      vortal_user_action: { type: enums.API_FILTER_TYPES.IN_INT },
      created_at: { type: enums.API_FILTER_TYPES.GTE_LTE_DATE_TIME },
      vortal_company_identifier_type: { type: enums.API_FILTER_TYPES.IN_INT },
      company_identifier: { type: enums.API_FILTER_TYPES.EQ_STRING },
      contact_vortal_user_code: { type: enums.API_FILTER_TYPES.EQ_STRING },
      company_vortal_company_code: { type: enums.API_FILTER_TYPES.EQ_STRING },
      project_vortal_operation_uid: { type: enums.API_FILTER_TYPES.EQ_STRING },
      contact_email: { type: enums.API_FILTER_TYPES.EQ_STRING },
      vortal_contact_id: { type: enums.API_FILTER_TYPES.IN_INT },
      vortal_company_id: { type: enums.API_FILTER_TYPES.IN_INT },
      vortal_project_id: { type: enums.API_FILTER_TYPES.IN_INT },
      project_smart_id: { type: enums.API_FILTER_TYPES.IN_INT },
      project_rsm_id: { type: enums.API_FILTER_TYPES.IN_INT },
    },
  },
  VORTAL_CONNECT_CONTACT_DATA: { url: '/v1/vortal_connect/contact_data' },
  VORTAL_CONNECT_TOKEN: { url: '/v1/vortal_connect/token' },
  VORTAL_CONNECT_UNSUBSCRIBE: { url: '/v1/vortal_connect/unsubscribe' },
  VORTAL_CONNECT_RELATED_COMPANIES: { url: '/v1/vortal_connect/related_companies' },
  VORTAL_CONNECT_RELATED_PROJECTS: { url: '/v1/vortal_connect/related_projects' },
};

export function getContacts(query = null) {
  const endpoint = BACKEND_ENDPOINTS.VORTAL_CONNECT_CONTACTS;
  const params = convertQueryToGetParams(endpoint, query);
  return BaseRepository.get(endpoint.url, {
    params,
    useIdmp: false,
  });
}

export function patchContact(contact_id, data = {}) {
  const path = replaceEnvPath(BACKEND_ENDPOINTS.VORTAL_CONNECT_CONTACT.url, { contact_id });
  return BaseRepository.patch(path, data);
}

export function getCompanies(query = {}) {
  const endpoint = BACKEND_ENDPOINTS.VORTAL_CONNECT_COMPANIES;
  const params = convertQueryToGetParams(endpoint, query);
  return BaseRepository.get(endpoint.url, {
    params,
    useIdmp: false,
  });
}

export function patchCompany(company_id, data = {}) {
  const path = replaceEnvPath(BACKEND_ENDPOINTS.VORTAL_CONNECT_COMPANY.url, { company_id });
  return BaseRepository.patch(path, data);
}

export function getProjects(query = {}) {
  const endpoint = BACKEND_ENDPOINTS.VORTAL_CONNECT_PROJECTS;
  const params = convertQueryToGetParams(endpoint, query);
  return BaseRepository.get(endpoint.url, {
    params,
    useIdmp: false,
  });
}

export function getNotificationEmails(query = {}) {
  const endpoint = BACKEND_ENDPOINTS.VORTAL_CONNECT_NOTIFICATION_EMAILS;
  const params = convertQueryToGetParams(endpoint, query);
  return BaseRepository.get(endpoint.url, {
    params,
    useIdmp: false,
  });
}

export function postNotificationEmail(data = {}) {
  const path = BACKEND_ENDPOINTS.VORTAL_CONNECT_NOTIFICATION_EMAILS.url;
  return BaseRepository.post(path, data);
}

export function putNotificationEmail(notification_email_id, data = {}) {
  const path = replaceEnvPath(BACKEND_ENDPOINTS.VORTAL_CONNECT_NOTIFICATION_EMAIL.url, { notification_email_id });
  return BaseRepository.put(path, data);
}

export function deleteNotificationEmail(notification_email_id) {
  const path = replaceEnvPath(BACKEND_ENDPOINTS.VORTAL_CONNECT_NOTIFICATION_EMAIL.url, { notification_email_id });
  return BaseRepository.delete(path);
}

export function getLogs(query = null) {
  const endpoint = BACKEND_ENDPOINTS.VORTAL_CONNECT_LOG;
  const params = convertQueryToGetParams(endpoint, query);
  return BaseRepository.get(endpoint.url, {
    params,
    useIdmp: false,
  });
}

export function getContactData(query = null) {
  const endpoint = BACKEND_ENDPOINTS.VORTAL_CONNECT_CONTACT_DATA;
  const params = convertQueryToGetParams(endpoint, query);
  return BaseRepository.get(endpoint.url, {
    params,
    useIdmp: false,
  });
}

export function getRelatedCompanies(query = null) {
  const endpoint = BACKEND_ENDPOINTS.VORTAL_CONNECT_RELATED_COMPANIES;
  const params = convertQueryToGetParams(endpoint, query);
  return BaseRepository.get(endpoint.url, {
    params,
    useIdmp: false,
  });
}

export function getRelatedProjects(query = null) {
  const endpoint = BACKEND_ENDPOINTS.VORTAL_CONNECT_RELATED_PROJECTS;
  const params = convertQueryToGetParams(endpoint, query);
  return BaseRepository.get(endpoint.url, {
    params,
    useIdmp: false,
  });
}

export function getToken(query = null) {
  const endpoint = BACKEND_ENDPOINTS.VORTAL_CONNECT_TOKEN;
  const params = convertQueryToGetParams(endpoint, query);
  return BaseRepository.get(endpoint.url, {
    params,
    useIdmp: false,
  });
}


