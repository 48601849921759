import { BaseRepository } from '@/api/baseRepository';
import { replaceEnvPath } from '@/shared/utils';
import { convertQueryToGetParams, convertQueryToJsonRpc } from '@/shared/api';
import enums from "@/config/enums";

const BACKEND_ENDPOINTS = {
  CUSTOM_FIELDSETS: {
    url: '/v1/custom_fieldsets',
    filter: {
      name: { type: enums.API_FILTER_TYPES.Q_STRING },
      client_id: { type: enums.API_FILTER_TYPES.IN_INT },
      entity_type_id: { type: enums.API_FILTER_TYPES.IN_INT },
      is_template: { type: enums.API_FILTER_TYPES.EQ_BOOLEAN },
    },
  },
  CUSTOM_FIELDSET: { url: '/v1/custom_fieldsets/{custom_fieldset_id}' },
  CUSTOM_FIELDSET_CLONE_FROM_TEMPLATE: { url: '/v1/custom_fieldsets/{custom_fieldset_id}/clone_from_template' },
  CUSTOM_FIELDSET_CLONE_TO_TEMPLATE: { url: '/v1/custom_fieldsets/{custom_fieldset_id}/clone_to_template' },
  CUSTOM_FIELDS: {
    url: '/v1/custom_fields',
    filter: {
      custom_fieldset_id: { type: enums.API_FILTER_TYPES.IN_INT },
      custom_field_type_id: { type: enums.API_FILTER_TYPES.IN_INT },
      show_in_filter: { type: enums.API_FILTER_TYPES.EQ_INT },
    },
  },
  CUSTOM_FIELD: { url: '/v1/custom_fields/{custom_field_id}' },
  CUSTOM_FIELD_SELECTIONS: {
    url: '/v1/custom_field_selections',
    filter: {
      custom_fieldset_id: { type: enums.API_FILTER_TYPES.IN_INT },
      custom_field_type_id: { type: enums.API_FILTER_TYPES.IN_INT },
      show_in_filter: { type: enums.API_FILTER_TYPES.EQ_INT },
    },
  },
  CUSTOM_FIELD_SELECTION: { url: '/v1/custom_field_selections/{custom_field_selection_id}' },
  CUSTOM_FIELD_VALUES: {
    url: '/v1/custom_field_values',
    filter: {
      client_id: { type: enums.API_FILTER_TYPES.IN_INT },
      entity_type_id: { type: enums.API_FILTER_TYPES.IN_INT },
      entity_id: { type: enums.API_FILTER_TYPES.IN_INT },
      custom_field_id: { type: enums.API_FILTER_TYPES.IN_INT },
    },
  },
  CUSTOM_FIELD_PRIORITIES: { url: '/v1/custom_field_priorities' },
  CUSTOM_FIELD_SELECTION_PRIORITIES: { url: '/v1/custom_field_selection_priorities' },
  CUSTOM_FIELD_FILES: { url: '/v1/cf_files' },
  CUSTOM_FIELD_FILE: { url: '/v1/cf_files/{cf_file_id}' },
  CUSTOM_FIELD_FILE_DOWNLOAD: { url: '/v1/cf_files/{cf_file_id}/download' },

  // metrics
  CUSTOM_FIELD_METRICS: {
    url: '/v1/custom_field_metrics',
    filter: {
      key: { type: enums.API_FILTER_TYPES.Q_STRING },
    },
  },
  CUSTOM_FIELD_METRIC: { url: '/v1/custom_field_metrics/{custom_field_metric_id}' },
  CUSTOM_FIELD_SELECTION_METRICS: {
    url: '/v1/custom_field_selection_metrics',
    filter: {
      key: { type: enums.API_FILTER_TYPES.Q_STRING },
    },
  },
  CUSTOM_FIELD_SELECTION_METRIC: { url: '/v1/custom_field_selection_metrics/{custom_field_selection_metric_id}' },
};

// Custom fieldsets
export function getCustomFieldsets(data = null) {
  const endpoint = BACKEND_ENDPOINTS.CUSTOM_FIELDSETS;
  const params = convertQueryToGetParams(endpoint, data);
  return BaseRepository.get(endpoint.url, {
    params,
    useIdmp: false,
  });
}

export function postCustomFieldset(data = {}) {
  const path = BACKEND_ENDPOINTS.CUSTOM_FIELDSETS.url;
  return BaseRepository.post(path, data);
}

export function getCustomFieldset(custom_fieldset_id) {
  const path = replaceEnvPath(BACKEND_ENDPOINTS.CUSTOM_FIELDSET.url, { custom_fieldset_id });
  return BaseRepository.get(path, null, {
    useIdmp: false,
  });
}

export function putCustomFieldset(custom_fieldset_id, data = {}) {
  const path = replaceEnvPath(BACKEND_ENDPOINTS.CUSTOM_FIELDSET.url, { custom_fieldset_id });
  return BaseRepository.put(path, data);
}

export function patchCustomFieldset(custom_fieldset_id, data = {}) {
  const path = replaceEnvPath(BACKEND_ENDPOINTS.CUSTOM_FIELDSET.url, { custom_fieldset_id });
  return BaseRepository.patch(path, data);
}

export function deleteCustomFieldset(custom_fieldset_id) {
  const path = replaceEnvPath(BACKEND_ENDPOINTS.CUSTOM_FIELDSET.url, { custom_fieldset_id });
  return BaseRepository.delete(path);
}

// Custom fields
export function getCustomFields(data = {}) {
  const endpoint = BACKEND_ENDPOINTS.CUSTOM_FIELDS;
  const params = convertQueryToGetParams(endpoint, data);
  return BaseRepository.get(endpoint.url, {
    params,
    useIdmp: false,
  });
}

export function postCustomField(data = {}) {
  const path = BACKEND_ENDPOINTS.CUSTOM_FIELDS.url;
  return BaseRepository.post(path, data);
}

export function getCustomField(custom_field_id) {
  const path = replaceEnvPath(BACKEND_ENDPOINTS.CUSTOM_FIELD.url, { custom_field_id });
  return BaseRepository.get(path, null, {
    useIdmp: false,
  });
}

export function putCustomField(custom_field_id, data = {}) {
  const path = replaceEnvPath(BACKEND_ENDPOINTS.CUSTOM_FIELD.url, { custom_field_id });
  return BaseRepository.put(path, data);
}

export function patchCustomField(custom_field_id, data = {}) {
  const path = replaceEnvPath(BACKEND_ENDPOINTS.CUSTOM_FIELD.url, { custom_field_id });
  return BaseRepository.patch(path, data);
}

export function deleteCustomField(custom_field_id) {
  const path = replaceEnvPath(BACKEND_ENDPOINTS.CUSTOM_FIELD.url, { custom_field_id });
  return BaseRepository.delete(path);
}

// Custom field selections
export function getCustomFieldSelections(data = {}) {
  const endpoint = BACKEND_ENDPOINTS.CUSTOM_FIELD_SELECTIONS;
  const params = convertQueryToGetParams(endpoint, data);
  return BaseRepository.get(endpoint.url, {
    params,
    useIdmp: false,
  });
}

export function postCustomFieldSelection(data = {}) {
  const path = BACKEND_ENDPOINTS.CUSTOM_FIELD_SELECTIONS.url;
  return BaseRepository.post(path, data);
}

export function getCustomFieldSelection(custom_field_selection_id) {
  const path = replaceEnvPath(BACKEND_ENDPOINTS.CUSTOM_FIELD_SELECTION.url, { custom_field_selection_id });
  return BaseRepository.get(path, null, {
    useIdmp: false,
  });
}

export function putCustomFieldSelection(custom_field_selection_id, data = {}) {
  const path = replaceEnvPath(BACKEND_ENDPOINTS.CUSTOM_FIELD_SELECTION.url, { custom_field_selection_id });
  return BaseRepository.put(path, data);
}

export function patchCustomFieldSelection(custom_field_selection_id, data = {}) {
  const path = replaceEnvPath(BACKEND_ENDPOINTS.CUSTOM_FIELD_SELECTION.url, { custom_field_selection_id });
  return BaseRepository.patch(path, data);
}

export function deleteCustomFieldSelection(custom_field_selection_id) {
  const path = replaceEnvPath(BACKEND_ENDPOINTS.CUSTOM_FIELD_SELECTION.url, { custom_field_selection_id });
  return BaseRepository.delete(path);
}

// Custom field values
export function getCustomFieldValues(data = {}) {
  const endpoint = BACKEND_ENDPOINTS.CUSTOM_FIELD_VALUES;
  const params = convertQueryToGetParams(endpoint, data);
  return BaseRepository.get(endpoint.url, {
    params,
    useIdmp: false,
  });
}

export function putCustomFieldValues(data = {}) {
  const path = BACKEND_ENDPOINTS.CUSTOM_FIELD_VALUES.url;
  return BaseRepository.put(path, data);
}

export function putCustomFieldPriorities(data = {}) {
  const path = BACKEND_ENDPOINTS.CUSTOM_FIELD_PRIORITIES.url;
  return BaseRepository.put(path, data);
}

export function putCustomFieldSelectionPriorities(data = {}) {
  const path = BACKEND_ENDPOINTS.CUSTOM_FIELD_SELECTION_PRIORITIES.url;
  return BaseRepository.put(path, data);
}

// Custom field files
export function getCustomFieldFiles(data = {}) {
  const endpoint = BACKEND_ENDPOINTS.CUSTOM_FIELD_FILES;
  const params = convertQueryToGetParams(endpoint, data);
  return BaseRepository.get(endpoint.url, {
    params,
    useIdmp: false,
  });
}

export function getCustomFieldFile(cf_file_id) {
  const path = replaceEnvPath(BACKEND_ENDPOINTS.CUSTOM_FIELD_FILE.url, { cf_file_id });
  return BaseRepository.get(path, {
    useIdmp: false,
  });
}

export function deleteCustomFieldFile(cf_file_id) {
  const path = replaceEnvPath(BACKEND_ENDPOINTS.CUSTOM_FIELD_FILE.url, { cf_file_id });
  return BaseRepository.delete(path);
}

export function downloadCustomFieldFile(cf_file_id) {
  const path = replaceEnvPath(BACKEND_ENDPOINTS.CUSTOM_FIELD_FILE_DOWNLOAD.url, { cf_file_id });
  return BaseRepository.get(path, {
    useIdmp: false,
  });
}

export function unauthorizedDownloadCustomFieldFile(cf_file_id, data = {}) {
  const endpoint = BACKEND_ENDPOINTS.CUSTOM_FIELD_FILE_DOWNLOAD;
  const path = replaceEnvPath(endpoint.url, { cf_file_id });
  const params = convertQueryToGetParams(endpoint, data);
  return BaseRepository.get(path, {
    params,
    useOpid: false,
    useIdmp: false,
  });
}

export function postCustomFieldFiles(data = {}, config) {
  const path = BACKEND_ENDPOINTS.CUSTOM_FIELD_FILES.url;
  return BaseRepository.post(path, data, {
    ...config,
    multipartForm: true,
  });
}


export function getCustomFieldMetrics(data = null) {
  const endpoint = BACKEND_ENDPOINTS.CUSTOM_FIELD_METRICS;
  const params = convertQueryToGetParams(endpoint, data);
  return BaseRepository.get(endpoint.url, {
    params,
    useIdmp: false,
  });
}

export function postCustomFieldMetric(data = {}) {
  const path = BACKEND_ENDPOINTS.CUSTOM_FIELD_METRICS.url;
  return BaseRepository.post(path, data);
}

export function getCustomFieldMetric(custom_field_metric_id) {
  const path = replaceEnvPath(BACKEND_ENDPOINTS.CUSTOM_FIELD_METRIC.url, { custom_field_metric_id });
  return BaseRepository.get(path, null, {
    useIdmp: false,
  });
}

export function putCustomFieldMetric(custom_field_metric_id, data = {}) {
  const path = replaceEnvPath(BACKEND_ENDPOINTS.CUSTOM_FIELD_METRIC.url, { custom_field_metric_id });
  return BaseRepository.put(path, data);
}

export function deleteCustomFieldMetric(custom_field_metric_id) {
  const path = replaceEnvPath(BACKEND_ENDPOINTS.CUSTOM_FIELD_METRIC.url, { custom_field_metric_id });
  return BaseRepository.delete(path);
}

// Custom field selection metrics
export function getCustomFieldSelectionMetrics(data = null) {
  const endpoint = BACKEND_ENDPOINTS.CUSTOM_FIELD_SELECTION_METRICS;
  const params = convertQueryToGetParams(endpoint, data);
  return BaseRepository.get(endpoint.url, {
    params,
    useIdmp: false,
  });
}

export function postCustomFieldSelectionMetric(data = {}) {
  const path = BACKEND_ENDPOINTS.CUSTOM_FIELD_SELECTION_METRICS.url;
  return BaseRepository.post(path, data);
}

export function getCustomFieldSelectionMetric(custom_field_selection_metric_id) {
  const path = replaceEnvPath(BACKEND_ENDPOINTS.CUSTOM_FIELD_SELECTION_METRIC.url, { custom_field_selection_metric_id });
  return BaseRepository.get(path, null, {
    useIdmp: false,
  });
}

export function putCustomFieldSelectionMetric(custom_field_selection_metric_id, data = {}) {
  const path = replaceEnvPath(BACKEND_ENDPOINTS.CUSTOM_FIELD_SELECTION_METRIC.url, { custom_field_selection_metric_id });
  return BaseRepository.put(path, data);
}

export function deleteCustomFieldSelectionMetric(custom_field_selection_metric_id) {
  const path = replaceEnvPath(BACKEND_ENDPOINTS.CUSTOM_FIELD_SELECTION_METRIC.url, { custom_field_selection_metric_id });
  return BaseRepository.delete(path);
}

export function putCustomFieldsetCloneFromTemplate(custom_fieldset_id, data = {}) {
  const path = replaceEnvPath(BACKEND_ENDPOINTS.CUSTOM_FIELDSET_CLONE_FROM_TEMPLATE.url, { custom_fieldset_id });
  return BaseRepository.put(path, data);
}

export function putCustomFieldsetCloneToTemplate(custom_fieldset_id, data = {}) {
  const path = replaceEnvPath(BACKEND_ENDPOINTS.CUSTOM_FIELDSET_CLONE_TO_TEMPLATE.url, { custom_fieldset_id });
  return BaseRepository.put(path, data);
}
