import { BaseRepository } from '@/api/baseRepository';
import { replaceEnvPath } from '@/shared/utils';
import { convertQueryToJsonRpc } from "@/shared/api";
import enums from "@/config/enums";

const BACKEND_ENDPOINTS = {
  DEFAULT_FIELDSETS_QUERY: {
    url: '/v1/default_fieldsets/query',
    filter: {
      country_id: { type: enums.API_FILTER_TYPES.IN_INT },
      list_type_id: { type: enums.API_FILTER_TYPES.IN_INT },
    },
  },
  DEFAULT_FIELDSETS: { url: '/v1/default_fieldsets' },
  DEFAULT_FIELDSET: { url: '/v1/default_fieldsets/{default_fieldset_id}' },
};

export function getDefaultFieldsets(data = null) {
  const endpoint = BACKEND_ENDPOINTS.DEFAULT_FIELDSETS_QUERY;
  const query = convertQueryToJsonRpc(endpoint, data);
  return BaseRepository.post(endpoint.url, query, { useIdmp: false, query: true });
}

export function postDefaultFieldset(data = {}) {
  const path = BACKEND_ENDPOINTS.DEFAULT_FIELDSETS.url;
  return BaseRepository.post(path, data);
}

export function getDefaultFieldset(default_fieldset_id) {
  const path = replaceEnvPath(BACKEND_ENDPOINTS.DEFAULT_FIELDSET.url, { default_fieldset_id });
  return BaseRepository.get(path, { useIdmp: false });
}

export function putDefaultFieldset(default_fieldset_id, data = {}) {
  console.log('putDefaultFieldset', default_fieldset_id, data);

  const path = replaceEnvPath(BACKEND_ENDPOINTS.DEFAULT_FIELDSET.url, { default_fieldset_id });
  return BaseRepository.put(path, data);
}

export function deleteDefaultFieldset(default_fieldset_id) {
  const path = replaceEnvPath(BACKEND_ENDPOINTS.DEFAULT_FIELDSET.url, { default_fieldset_id });
  return BaseRepository.delete(path);
}
