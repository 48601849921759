import { BaseRepository } from '@/api/baseRepository';
import { replaceEnvPath } from '@/shared/utils';
import { convertQueryToGetParams, convertQueryToJsonRpc } from '@/shared/api';
import enums from "@/config/enums";

const BACKEND_ENDPOINTS = {
  RELEASE_NOTES_QUERY: {
    url: '/v1/release_notes',
    filter: {
      id: { type: enums.API_FILTER_TYPES.IN_INT },
      title: { type: enums.API_FILTER_TYPES.Q_STRING },
      release_version: { type: enums.API_FILTER_TYPES.IN_STRING },
      release_date: { type: enums.API_FILTER_TYPES.GTE_LTE_DATE_TIME },
      created_at: { type: enums.API_FILTER_TYPES.GTE_LTE_DATE_TIME },
      modified_at: { type: enums.API_FILTER_TYPES.GTE_LTE_DATE_TIME },
      countries: { type: enums.API_FILTER_TYPES.IN_INT },
      enable_languages: { type: enums.API_FILTER_TYPES.IN_NOT_IN_INT },
      publish_status: { type: enums.API_FILTER_TYPES.EQ_BOOLEAN_NUMBER },
    },
  },
  RELEASE_NOTES: { url: '/v1/release_notes' },
  RELEASE_NOTES_VERSIONS: { url: '/v1/release_notes_versions' },
  RELEASE_NOTE: { url: '/v1/release_notes/{release_note_id}' },
};

export function getReleaseNotes(query = null) {
  const endpoint = BACKEND_ENDPOINTS.RELEASE_NOTES_QUERY;
  const params = convertQueryToGetParams(endpoint, query);
  return BaseRepository.get(endpoint.url, {
    params,
    useIdmp: false,
  });
}

export function getReleaseNotesVersions(query = {}) {
  const endpoint = BACKEND_ENDPOINTS.RELEASE_NOTES_VERSIONS;
  const params = convertQueryToGetParams(endpoint, query);
  return BaseRepository.get(endpoint.url, {
    params,
    useIdmp: false,
  });
}

export function postReleaseNote(data = {}) {
  const path = BACKEND_ENDPOINTS.RELEASE_NOTES.url;
  return BaseRepository.post(path, data);
}

export function getReleaseNote(release_note_id) {
  const path = replaceEnvPath(BACKEND_ENDPOINTS.RELEASE_NOTE.url, { release_note_id });
  return BaseRepository.get(path, null, {
    useIdmp: false,
  });
}

export function deleteReleaseNote(release_note_id) {
  const path = replaceEnvPath(BACKEND_ENDPOINTS.RELEASE_NOTE.url, { release_note_id });
  return BaseRepository.delete(path);
}

export function putReleaseNote(release_note_id, data = {}) {
  const path = replaceEnvPath(BACKEND_ENDPOINTS.RELEASE_NOTE.url, { release_note_id });
  return BaseRepository.put(path, data);
}

export function patchReleaseNote(release_note_id, data = {}) {
  const path = replaceEnvPath(BACKEND_ENDPOINTS.RELEASE_NOTE.url, { release_note_id });
  return BaseRepository.patch(path, data);
}

export default {
  getReleaseNotes,
  getReleaseNotesVersions,
  getReleaseNote,
  postReleaseNote,
  deleteReleaseNote,
  putReleaseNote,
  patchReleaseNote,
};
