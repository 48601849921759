import {
  API_FILTER_TYPES,
} from '@/config/enums';
import { BaseRepository } from "@/api/baseRepository";
import { replaceEnvPath } from "@/shared/utils";
import { convertQueryToGetParams } from "@/shared/api";

const BACKEND_ENDPOINTS = {
  USER_TRANSFER_TASKS: {
    url: '/v2/user_transfer_tasks',
    filter: {
      creator_id: { type: API_FILTER_TYPES.IN_INT },
      from_user_id: { type: API_FILTER_TYPES.IN_INT },
      to_user_id: { type: API_FILTER_TYPES.IN_INT },
      stage_id: { type: API_FILTER_TYPES.IN_INT },
      stage_status: { type: API_FILTER_TYPES.IN_INT },
      task_status: { type: API_FILTER_TYPES.EQ_INT },
    },
  },
  USER_TRANSFER_TASK: { url: '/v2/user_transfer_tasks/{utt_id}' },
};

export function getTransferTasks(data = null) {
  const endpoint = BACKEND_ENDPOINTS.USER_TRANSFER_TASKS;
  const params = convertQueryToGetParams(endpoint, data);
  return BaseRepository.get(endpoint.url, { params, useIdmp: false });
}

export function postTransferTask(data = {}) {
  const path = BACKEND_ENDPOINTS.USER_TRANSFER_TASKS.url;
  return BaseRepository.post(path, data);
}

export function getTransferTask(utt_id) {
  const path = replaceEnvPath(BACKEND_ENDPOINTS.USER_TRANSFER_TASK.url, { utt_id });
  return BaseRepository.get(path, { useIdmp: false });
}
