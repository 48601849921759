import { BaseRepository } from '@/api/baseRepository';
import { replaceEnvPath } from '@/shared/utils';
import { convertQueryToGetParams, convertQueryToJsonRpc } from '@/shared/api';
import enums from "@/config/enums";

const BACKEND_ENDPOINTS = {
  SUGGEST_GEO: { url: '/v1/suggest_geo/{list_type_id}' },
  ADMINISTRATIVE_UNITS: { url: '/v1/administrative_units' },
  ADMINISTRATIVE_UNITS_QUERY: {
    url: '/v1/administrative_units/query',
    filter: {
      id: { type: enums.API_FILTER_TYPES.IN_INT },
      administrative_units: { type: enums.API_FILTER_TYPES.IN_INT },
      administrative_unit_type_id: { type: enums.API_FILTER_TYPES.IN_INT },
      country_id: { type: enums.API_FILTER_TYPES.IN_INT },
      region_id: { type: enums.API_FILTER_TYPES.IN_INT },
      district_id: { type: enums.API_FILTER_TYPES.IN_INT },
    },
  },
};

// Suggest the geography (administrative units) by string
export function getSuggestGeo(list_type_id, query = {}) {
  const endpoint = BACKEND_ENDPOINTS.SUGGEST_GEO;
  const path = replaceEnvPath(endpoint.url, { list_type_id });
  const params = convertQueryToGetParams(endpoint, query);
  return BaseRepository.get(path, {
    params,
    useIdmp: false,
  });
}
// Get the administrative units
export function getAdministrativeUnits(data = null) {
  const endpoint = BACKEND_ENDPOINTS.ADMINISTRATIVE_UNITS_QUERY;
  const query = convertQueryToJsonRpc(endpoint, data);
  return BaseRepository.post(endpoint.url, query, {
    useIdmp: false,
    query: true,
  });
}

export default {
  getSuggestGeo,
  getAdministrativeUnits,
};
