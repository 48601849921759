import { BaseRepository } from '@/api/baseRepository';
import { replaceEnvPath } from '@/shared/utils';
import { convertQueryToGetParams, convertQueryToJsonRpc } from '@/shared/api';
import { API_FILTER_TYPES } from "@/config/enums";

const BACKEND_ENDPOINTS = {
  SYSTEM_COMPONENTS: { url: '/v2/system/components' },
  SYSTEM_COMPONENT: { url: '/v2/system/components/{component_id}' },
  SYSTEM_MODULES: {
    url: '/v2/system/modules',
    filter: {
      tags: { type: API_FILTER_TYPES.IN_STRING },
    },
  },
  SYSTEM_MODULE: { url: '/v2/system/modules/{module_id}' },
};

// Get the list of the system components
export function getComponents(data = null) {
  const endpoint = BACKEND_ENDPOINTS.SYSTEM_COMPONENTS;
  const query = convertQueryToGetParams(endpoint, data);
  return BaseRepository.get(endpoint.url, {
    params: query,
    useIdmp: false,
  });
}

// Add Component
export function postComponent(data = {}) {
  const path = BACKEND_ENDPOINTS.SYSTEM_COMPONENTS.url;
  return BaseRepository.post(path, data);
}

// Get Component
export function getComponent(component_id) {
  const path = replaceEnvPath(BACKEND_ENDPOINTS.SYSTEM_COMPONENT.url, { component_id });
  return BaseRepository.get(path, {
    useIdmp: false,
  });
}

// Update Component
export function putComponent(component_id, data = {}) {
  const path = replaceEnvPath(BACKEND_ENDPOINTS.SYSTEM_COMPONENT.url, { component_id });
  return BaseRepository.put(path, data);
}

// Delete Component
export function deleteComponent(component_id) {
  const path = replaceEnvPath(BACKEND_ENDPOINTS.SYSTEM_COMPONENT.url, { component_id });
  return BaseRepository.delete(path);
}

// Get the list of the system modules
export function getModules(data = null) {
  const endpoint = BACKEND_ENDPOINTS.SYSTEM_MODULES;
  const params = convertQueryToGetParams(endpoint, data);
  return BaseRepository.get(endpoint.url, {
    params,
    useIdmp: false,
  });
}

// Add Module
export function postModule(data = {}) {
  const path = BACKEND_ENDPOINTS.SYSTEM_MODULES.url
  return BaseRepository.post(path, data);
}

// Get Module
export function getModule(module_id) {
  const path = replaceEnvPath(BACKEND_ENDPOINTS.SYSTEM_MODULE.url, { module_id });
  return BaseRepository.get(path, {
    useIdmp: false,
  });
}

// Update Module
export function putModule(module_id, data = {}) {
  const path = replaceEnvPath(BACKEND_ENDPOINTS.SYSTEM_MODULE.url, { module_id });
  return BaseRepository.put(path, data);
}

// Delete Module
export function deleteModule(module_id) {
  const path = replaceEnvPath(BACKEND_ENDPOINTS.SYSTEM_MODULE.url, { module_id });
  return BaseRepository.delete(path);
}
