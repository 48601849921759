import { BaseRepository } from '@/api/baseRepository';
import { replaceEnvPath } from '@/shared/utils';
import { convertQueryToGetParams, convertQueryToJsonRpc } from '@/shared/api';
import enums from "@/config/enums";

const BACKEND_ENDPOINTS = {
  MYRAN_USER_VERSIONS: {
    url: '/v1/myran/user_versions/query',
    filter: {
      id: { type: enums.API_FILTER_TYPES.IN_INT },
      client_version_id: { type: enums.API_FILTER_TYPES.IN_INT },
      license_id: { type: enums.API_FILTER_TYPES.IN_INT },
      language_id: { type: enums.API_FILTER_TYPES.IN_INT },
      currency_id: { type: enums.API_FILTER_TYPES.IN_INT },
      source_id: { type: enums.API_FILTER_TYPES.IN_INT },
      user_id: { type: enums.API_FILTER_TYPES.IN_INT },
      user_import_status: { type: enums.API_FILTER_TYPES.IN_INT },
      created_at: { type: enums.API_FILTER_TYPES.GTE_LTE_DATE_TIME },
      email: { type: enums.API_FILTER_TYPES.Q_STRING },
      name: { type: enums.API_FILTER_TYPES.Q_STRING },
    },
  },
  MYRAN_CLIENT_VERSIONS: {
    url: '/v1/myran/client_versions/query',
    filter: {
      id: { type: enums.API_FILTER_TYPES.IN_INT },
      subscription_customer_id: { type: enums.API_FILTER_TYPES.IN_INT },
      history_start_date: { type: enums.API_FILTER_TYPES.GTE_LTE_DATE_TIME },
      package_expire_at: { type: enums.API_FILTER_TYPES.GTE_LTE_DATE_TIME },
      source_type_id: { type: enums.API_FILTER_TYPES.IN_INT },
      source_id: { type: enums.API_FILTER_TYPES.IN_INT },
      country_id: { type: enums.API_FILTER_TYPES.IN_INT },
      created_at: { type: enums.API_FILTER_TYPES.GTE_LTE_DATE_TIME },
      client_id: { type: enums.API_FILTER_TYPES.IN_INT },
      name: { type: enums.API_FILTER_TYPES.Q_STRING },
      support_name: { type: enums.API_FILTER_TYPES.Q_STRING },
      support_email: { type: enums.API_FILTER_TYPES.Q_STRING },
    },
  },
  MYRAN_SUBSCRIPTION_VERSIONS: {
    url: '/v1/myran/subscription_versions/query',
    filter: {
      id: { type: enums.API_FILTER_TYPES.IN_INT },
      subscription_customer_id: { type: enums.API_FILTER_TYPES.IN_INT },
      source_type_id: { type: enums.API_FILTER_TYPES.IN_INT },
      source_id: { type: enums.API_FILTER_TYPES.IN_INT },
      source_customer_id: { type: enums.API_FILTER_TYPES.IN_INT },
      source_created_at: { type: enums.API_FILTER_TYPES.GTE_LTE_DATE_TIME },
      source_modified_at: { type: enums.API_FILTER_TYPES.GTE_LTE_DATE_TIME },
      start_date: { type: enums.API_FILTER_TYPES.GTE_LTE_DATE_TIME },
      end_date: { type: enums.API_FILTER_TYPES.GTE_LTE_DATE_TIME },
      expiration_date: { type: enums.API_FILTER_TYPES.GTE_LTE_DATE_TIME },
      created_at: { type: enums.API_FILTER_TYPES.GTE_LTE_DATE_TIME },
      customer_name: { type: enums.API_FILTER_TYPES.Q_STRING },
      support_name: { type: enums.API_FILTER_TYPES.Q_STRING },
      support_email: { type: enums.API_FILTER_TYPES.Q_STRING },
    },
    queryFilter: {
      status: { type: enums.API_FILTER_TYPES.EQ_INT },
    },
  },
  MYRAN_USER_PROFILES: {
    url: '/v1/myran/user_profiles/query',
    filter: {
      id: { type: enums.API_FILTER_TYPES.IN_INT },
      subscription_version_id: { type: enums.API_FILTER_TYPES.IN_INT },
      source_id: { type: enums.API_FILTER_TYPES.IN_INT },
      source_created_at: { type: enums.API_FILTER_TYPES.GTE_LTE_DATE_TIME },
      source_modified_at: { type: enums.API_FILTER_TYPES.GTE_LTE_DATE_TIME },
      created_at: { type: enums.API_FILTER_TYPES.GTE_LTE_DATE_TIME },
      valid: { type: enums.API_FILTER_TYPES.EQ_BOOLEAN_NUMBER },
      email: { type: enums.API_FILTER_TYPES.Q_STRING },
      first_name: { type: enums.API_FILTER_TYPES.Q_STRING },
      last_name: { type: enums.API_FILTER_TYPES.Q_STRING },
    },
  },
  MYRAN_SUSPENDED_USERS: {
    url: '/v1/myran/suspended_users/query',
    filter: {
      id: { type: enums.API_FILTER_TYPES.IN_INT },
      client_version_id: { type: enums.API_FILTER_TYPES.IN_INT },
      source_id: { type: enums.API_FILTER_TYPES.IN_INT },
      user_id: { type: enums.API_FILTER_TYPES.IN_INT },
      created_at: { type: enums.API_FILTER_TYPES.GTE_LTE_DATE_TIME },
    },
  },

  MYRAN_ACTIVE_SUBSCRIPTION_VERSIONS: {
    url: '/v1/myran/active_subscription_version/{source_customer_id}',
  },
  MYRAN_UPDATE_CUSTOMER_SUBSCRIPTION: {
    url: '/v1/myran/update_customer_subscription/{source_id}',
  },
};

// Get User Versions
export function getUserVersions(data = null) {
  const endpoint = BACKEND_ENDPOINTS.MYRAN_USER_VERSIONS;
  const query = convertQueryToJsonRpc(endpoint, data);
  return BaseRepository.post(endpoint.url, query, {
    useIdmp: false,
    query: true,
  });
}

// Get Client Versions
export function getClientVersions(data = null) {
  const endpoint = BACKEND_ENDPOINTS.MYRAN_CLIENT_VERSIONS;
  const query = convertQueryToJsonRpc(endpoint, data);
  return BaseRepository.post(endpoint.url, query, {
    useIdmp: false,
    query: true,
  });
}

// Get Subscription Versions
export function getSubscriptionVersions(data = null) {
  const endpoint = BACKEND_ENDPOINTS.MYRAN_SUBSCRIPTION_VERSIONS;
  const query = convertQueryToJsonRpc(endpoint, data);
  return BaseRepository.post(endpoint.url, query, {
    useIdmp: false,
    query: true,
  });
}

// Get User Profiles
export function getUserProfiles(data = null) {
  const endpoint = BACKEND_ENDPOINTS.MYRAN_USER_PROFILES;
  const query = convertQueryToJsonRpc(endpoint, data);
  return BaseRepository.post(endpoint.url, query, {
    useIdmp: false,
    query: true,
  });
}

// Get Suspended Users Log
export function getSuspendedUsers(data = null) {
  const endpoint = BACKEND_ENDPOINTS.MYRAN_SUSPENDED_USERS;
  const query = convertQueryToJsonRpc(endpoint, data);
  return BaseRepository.post(endpoint.url, query, {
    useIdmp: false,
    query: true,
  });
}

// Get Active subscription version
export function getActiveSubscriptionVersions(source_customer_id, data = {}) {
  const endpoint = BACKEND_ENDPOINTS.MYRAN_ACTIVE_SUBSCRIPTION_VERSIONS;
  const path = replaceEnvPath(endpoint.url, { source_customer_id });
  const params = convertQueryToGetParams(endpoint, data);
  return BaseRepository.get(path, {
    params,
    useIdmp: false,
  });
}

export function updateCustomerSubscription(source_id, data = {}) {
  const endpoint = BACKEND_ENDPOINTS.MYRAN_UPDATE_CUSTOMER_SUBSCRIPTION;
  const path = replaceEnvPath(endpoint.url, { source_id });
  const params = convertQueryToGetParams(endpoint, data);
  return BaseRepository.get(path, {
    params,
    useIdmp: false,
  });
}

export default {
  getUserVersions,
  getClientVersions,
  getSubscriptionVersions,
  getUserProfiles,
  getSuspendedUsers,
  getActiveSubscriptionVersions,
  updateCustomerSubscription,
};
