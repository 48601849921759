import { BaseRepository } from '@/api/baseRepository';
import { replaceEnvPath } from '@/shared/utils';
import { convertQueryToGetParams, convertQueryToJsonRpc } from '@/shared/api';
import enums from "@/config/enums";

const BACKEND_ENDPOINTS = {
  FILES_QUERY: {
    url: '/v1/files/query',
    filter: {
      id: { type: enums.API_FILTER_TYPES.IN_INT },
      user_id: { type: enums.API_FILTER_TYPES.IN_INT },
      client_id: { type: enums.API_FILTER_TYPES.IN_INT },
      file_type: { type: enums.API_FILTER_TYPES.IN_INT },
      file_status: { type: enums.API_FILTER_TYPES.IN_INT },
      has_errors: { type: enums.API_FILTER_TYPES.EQ_BOOLEAN_NUMBER },
      created_at: { type: enums.API_FILTER_TYPES.GTE_LTE_DATE_TIME },
      list_type_id: { type: enums.API_FILTER_TYPES.IN_INT },
    },
  },
  FILES: { url: '/v1/files' },
  FILE: { url: '/v1/files/{file_id}' },
  FILE_DOWNLOAD: { url: '/v1/files/{file_id}/download' },
};

// Get files
export function getFiles(data = null) {
  const endpoint = BACKEND_ENDPOINTS.FILES_QUERY;
  const query = convertQueryToJsonRpc(endpoint, data);
  return BaseRepository.post(endpoint.url, query, {
    useIdmp: false,
    query: true,
  });
}

export function postFile(data = {}) {
  const path = BACKEND_ENDPOINTS.FILES.url;
  return BaseRepository.post(path, data);
}

export function getFile(file_id) {
  const path = replaceEnvPath(BACKEND_ENDPOINTS.FILE.url, { file_id });
  return BaseRepository.get(path, null, {
    useIdmp: false,
  });
}

export function deleteFile(file_id) {
  const path = replaceEnvPath(BACKEND_ENDPOINTS.FILE.url, { file_id });
  return BaseRepository.delete(path);
}

export function downloadFile(file_id, data = {}) {
  const endpoint = BACKEND_ENDPOINTS.FILE_DOWNLOAD;
  const path = replaceEnvPath(endpoint.url, { file_id });
  const params = convertQueryToGetParams(endpoint, data);
  return BaseRepository.get(path, {
    params,
    useIdmp: false,
  });
}

export function unauthorizedDownloadFile(file_id, data = {}) {
  const endpoint = BACKEND_ENDPOINTS.FILE_DOWNLOAD;
  const path = replaceEnvPath(endpoint.url, { file_id });
  const params = convertQueryToGetParams(endpoint, data);
  return BaseRepository.get(path, {
    params,
    useOpid: false,
    useIdmp: false,
  });
}

export default {
  getFiles,
  postFile,
  getFile,
  deleteFile,
  downloadFile,
  unauthorizedDownloadFile,
};
