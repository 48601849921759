import { BaseRepository } from '@/api/baseRepository';
import { convertQueryToGetParams, convertQueryToJsonRpc } from "@/shared/api";
import { API_FILTER_TYPES } from "@/config/enums";
import { replaceEnvPath } from "@/shared/utils";

const BACKEND_ENDPOINTS = {
  ME_DASHBOARD_WIDGETS: { url: '/v1/me/user_dashboard_widgets' },
  ME_DASHBOARD_WIDGETS_STATS: { url: '/v1/me/user_dashboard_widgets/{udw_id}/stats' },
  ME_DASHBOARD_WIDGETS_PROJECT_TRENDS: { url: '/v1/me/dashboard_widgets/project_trends' },
  USER_DASHBOARD_WIDGETS: { url: '/v1/user_dashboard_widgets' },
  USER_DASHBOARD_WIDGET: { url: '/v1/user_dashboard_widgets/{udw_id}' },
  COUNTRY_DEFAULT_DASHBOARD_WIDGETS_QUERY: {
    url: '/v1/country_default_dashboard_widgets/query',
    filter: {
      country_id: { type: API_FILTER_TYPES.IN_INT },
      dashboard_widget_type_id: { type: API_FILTER_TYPES.IN_INT },
    },
  },
  COUNTRY_DASHBOARD_WIDGETS: { url: '/v1/country_default_dashboard_widgets/{country_id}' },
};

export function getMeDashboardWidgets() {
  return BaseRepository.get(BACKEND_ENDPOINTS.ME_DASHBOARD_WIDGETS.url, { useIdmp: false });
}

export function updateMeDashboardWidgets(widgets = []) {
  return BaseRepository.put(BACKEND_ENDPOINTS.ME_DASHBOARD_WIDGETS.url, widgets);
}

export function patchUserDashboardWidget(udw_id, data = {}) {
  const path = replaceEnvPath(BACKEND_ENDPOINTS.USER_DASHBOARD_WIDGET.url, { udw_id });
  return BaseRepository.patch(path, data);
}

export function updateUserDashboardWidget(widgets = []) {
  return BaseRepository.put(BACKEND_ENDPOINTS.USER_DASHBOARD_WIDGETS.url, widgets);
}

export function getDashboardWidgetProjectTrends(data = {}) {
  const endpoint = BACKEND_ENDPOINTS.ME_DASHBOARD_WIDGETS_PROJECT_TRENDS;
  const params = convertQueryToGetParams(endpoint, data);
  return BaseRepository.get(endpoint.url, {
    params,
    useIdmp: false,
  });
}

export function getCountryDefaultDashboardWidgets(data = null) {
  const endpoint = BACKEND_ENDPOINTS.COUNTRY_DEFAULT_DASHBOARD_WIDGETS_QUERY;
  const query = convertQueryToJsonRpc(endpoint, data);
  return BaseRepository.post(endpoint.url, query, {
    useIdmp: false,
    query: true,
  });
}

export function putCountryDefaultDashboardWidgets(country_id, data = {}) {
  const path = replaceEnvPath(BACKEND_ENDPOINTS.COUNTRY_DASHBOARD_WIDGETS.url, { country_id });
  return BaseRepository.put(path, data);
}

export function putDashboardStats(udw_id, dashboard_widget_type_id, data = {}) {
  const path = replaceEnvPath(BACKEND_ENDPOINTS.ME_DASHBOARD_WIDGETS_STATS.url, { udw_id });
  return BaseRepository.put(path, {
    dashboard_widget_type_id,
    ...data,
  });
}
