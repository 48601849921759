import { BaseRepository } from '@/api/baseRepository';
import { convertQueryToGetParams } from '@/shared/api';
import enums from "@/config/enums";

const BACKEND_ENDPOINTS = {
  EMAIL_SENDER_LOG: {
    url: '/v1/email_sender/log',
    filter: {
      user_id: { type: enums.API_FILTER_TYPES.IN_INT },
      created_at: { type: enums.API_FILTER_TYPES.GTE_LTE_DATE_TIME },
      message_id: { type: enums.API_FILTER_TYPES.IN_INT },
      campaign_id: { type: enums.API_FILTER_TYPES.IN_INT },
      provider_msg_id: { type: enums.API_FILTER_TYPES.EQ_STRING },
      subject: { type: enums.API_FILTER_TYPES.EQ_STRING },
      email: { type: enums.API_FILTER_TYPES.EQ_STRING },
      sent: { type: enums.API_FILTER_TYPES.EQ_BOOLEAN_NUMBER },
      email_provider_event_type_id: { type: enums.API_FILTER_TYPES.IN_INT },
    },
  },
  EMAIL_SENDER_EVENTS: {
    url: '/v1/email_sender/events',
    filter: {
      user_id: { type: enums.API_FILTER_TYPES.IN_INT },
      event_registered_at: { type: enums.API_FILTER_TYPES.GTE_LTE_DATE_TIME },
      message_id: { type: enums.API_FILTER_TYPES.IN_INT },
      campaign_id: { type: enums.API_FILTER_TYPES.IN_INT },
      provider_msg_id: { type: enums.API_FILTER_TYPES.EQ_STRING },
      email: { type: enums.API_FILTER_TYPES.EQ_STRING },
      email_provider_event_type_id: { type: enums.API_FILTER_TYPES.IN_INT },
    },
  },
};

// Get webhooks
export function getEmailSenderLogs(data = {}) {
  const endpoint = BACKEND_ENDPOINTS.EMAIL_SENDER_LOG;
  const params = convertQueryToGetParams(endpoint, data);
  return BaseRepository.get(endpoint.url, {
    params,
    useIdmp: false,
  });
}

export function getEmailSenderEvents(data = {}) {
  const endpoint = BACKEND_ENDPOINTS.EMAIL_SENDER_EVENTS;
  const params = convertQueryToGetParams(endpoint, data);
  return BaseRepository.get(endpoint.url, {
    params,
    useIdmp: false,
  });
}


export default {
  getEmailSenderLogs,
  getEmailSenderEvents,
};
