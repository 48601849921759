import { BaseRepository } from '@/api/baseRepository';
import { replaceEnvPath } from '@/shared/utils';
import { convertQueryToGetParams, convertQueryToJsonRpc } from '@/shared/api';
import enums from "@/config/enums";

const BACKEND_ENDPOINTS = {
  // Webhooks
  WEBHOOKS_QUERY: {
    url: '/v1/webhooks/query',
    filter: {
      id: { type: enums.API_FILTER_TYPES.IN_INT },
      client_id: { type: enums.API_FILTER_TYPES.IN_INT },
      webhook_version_id: { type: enums.API_FILTER_TYPES.IN_INT },
      activated: { type: enums.API_FILTER_TYPES.EQ_BOOLEAN_NUMBER },
      blocked: { type: enums.API_FILTER_TYPES.EQ_BOOLEAN_NUMBER },
      webhook_event_type: { type: enums.API_FILTER_TYPES.IN_INT },
    },
  },
  WEBHOOKS: { url: '/v1/webhooks' },
  WEBHOOK: { url: '/v1/webhooks/{webhook_id}' },
  WEBHOOK_VERSIONS: { url: '/v1/webhook_versions' },
  WEBHOOK_SECRET_KEY: { url: '/v1/webhooks/{webhook_id}/secret_key' },

  // Webhook queue
  WEBHOOK_QUEUES_QUERY: {
    url: '/v1/webhook_queue/query',
    filter: {
      webhook_id: { type: enums.API_FILTER_TYPES.IN_INT },
      webhook_version_id: { type: enums.API_FILTER_TYPES.IN_INT },
      webhook_event_type: { type: enums.API_FILTER_TYPES.IN_INT },
      client_id: { type: enums.API_FILTER_TYPES.IN_INT },
      activated: { type: enums.API_FILTER_TYPES.EQ_BOOLEAN_NUMBER },
      blocked: { type: enums.API_FILTER_TYPES.EQ_BOOLEAN_NUMBER },
      created_at: { type: enums.API_FILTER_TYPES.GTE_LTE_DATE_TIME },
      scheduled_at: { type: enums.API_FILTER_TYPES.GTE_LTE_DATE_TIME },
      launched_at: { type: enums.API_FILTER_TYPES.GTE_LTE_DATE_TIME },
      sent_at: { type: enums.API_FILTER_TYPES.GTE_LTE_DATE_TIME },
      status: { type: enums.API_FILTER_TYPES.IN_INT },
      retry: { type: enums.API_FILTER_TYPES.IN_INT },
      has_error: { type: enums.API_FILTER_TYPES.EQ_BOOLEAN_NUMBER },
    },
  },
  WEBHOOK_QUEUE: { url: '/v1/webhook_queue/{webhook_queue_id}' },
};

// Get webhooks
export function getWebhooks(data = null) {
  const endpoint = BACKEND_ENDPOINTS.WEBHOOKS_QUERY;
  const query = convertQueryToJsonRpc(endpoint, data);
  return BaseRepository.post(endpoint.url, query, {
    useIdmp: false,
    query: true,
  });
}

export function postWebhook(data = {}) {
  const path = BACKEND_ENDPOINTS.WEBHOOKS.url;
  return BaseRepository.post(path, data);
}

export function getWebhook(webhook_id) {
  const path = replaceEnvPath(BACKEND_ENDPOINTS.WEBHOOK.url, { webhook_id });
  return BaseRepository.get(path, null, {
    useIdmp: false,
  });
}

export function putWebhook(webhook_id, data = {}) {
  const path = replaceEnvPath(BACKEND_ENDPOINTS.WEBHOOK.url, { webhook_id });
  return BaseRepository.put(path, data);
}

export function patchWebhook(webhook_id, data = {}) {
  const path = replaceEnvPath(BACKEND_ENDPOINTS.WEBHOOK.url, { webhook_id });
  return BaseRepository.patch(path, data);
}

export function deleteWebhook(webhook_id) {
  const path = replaceEnvPath(BACKEND_ENDPOINTS.WEBHOOK.url, { webhook_id });
  return BaseRepository.delete(path);
}

export function getWebhookVersions(data = {}) {
  const endpoint = BACKEND_ENDPOINTS.WEBHOOK_VERSIONS;
  const params = convertQueryToGetParams(endpoint, data);
  return BaseRepository.get(endpoint.url, {
    params,
    useIdmp: false,
  });
}

// Generate new WebHook Secret Key
export function postWebhookSecretKey(webhook_id, data = {}) {
  const path = replaceEnvPath(BACKEND_ENDPOINTS.WEBHOOK_SECRET_KEY.url, { webhook_id });
  return BaseRepository.post(path, data);
}

// Get webhooks
export function getWebhookQueues(data = null) {
  const endpoint = BACKEND_ENDPOINTS.WEBHOOK_QUEUES_QUERY;
  const query = convertQueryToJsonRpc(endpoint, data);
  return BaseRepository.post(endpoint.url, query, {
    useIdmp: false,
    query: true,
  });
}

export function getWebhookQueue(webhook_queue_id) {
  const path = replaceEnvPath(BACKEND_ENDPOINTS.WEBHOOK_QUEUE.url, { webhook_queue_id });
  return BaseRepository.get(path, null, {
    useIdmp: false,
  });
}

export default {
  getWebhooks,
  postWebhook,
  getWebhook,
  putWebhook,
  patchWebhook,
  deleteWebhook,
  getWebhookVersions,
  postWebhookSecretKey,
  getWebhookQueues,
  getWebhookQueue,
};
