import { BaseRepository } from '@/api/baseRepository';
import { replaceEnvPath } from '@/shared/utils';
import { convertQueryToGetParams } from '@/shared/api';
import enums from "@/config/enums";

const BACKEND_ENDPOINTS = {
  SEARCHES: {
    url: '/v1/me/searches',
    filter: {
      list_type_id: { type: enums.API_FILTER_TYPES.EQ_INT },
    },
  },
  SEARCH: { url: '/v1/me/searches/{search_id}' },

  // User / Saved Search Notifications
  USER_SAVE_SEARCH_NOTIFICATIONS: {
    url: '/v1/ssn',
    filter: {
      id: { type: enums.API_FILTER_TYPES.IN_INT },
      search_id: { type: enums.API_FILTER_TYPES.IN_INT },
      user_id: { type: enums.API_FILTER_TYPES.IN_INT },
      client_id: { type: enums.API_FILTER_TYPES.IN_INT },
      list_type_id: { type: enums.API_FILTER_TYPES.IN_INT },
      access_code: { type: enums.API_FILTER_TYPES.EQ_STRING },
      created_at: { type: enums.API_FILTER_TYPES.GTE_LTE_DATE_TIME },
    },
  },
  USER_SAVE_SEARCH_NOTIFICATION: { url: '/v1/ssn/{ssn_identifier}' },
};

// Get the list of My Searches
export function getSearchesList(data = null) {
  const endpoint = BACKEND_ENDPOINTS.SEARCHES;
  const params = convertQueryToGetParams(endpoint, data);
  return BaseRepository.get(endpoint.url, { params, useIdmp: false });
}

// Add search to My Searches
export function postSearch(data = {}) {
  const path = BACKEND_ENDPOINTS.SEARCHES.url;
  return BaseRepository.post(path, data);
}

// Get My Search
export function getSearch(search_id) {
  const path = replaceEnvPath(BACKEND_ENDPOINTS.SEARCH.url, { search_id });
  return BaseRepository.get(path, { useIdmp: false });
}

// Update Search settings
export function putSearch(search_id, data = {}) {
  const path = replaceEnvPath(BACKEND_ENDPOINTS.SEARCH.url, { search_id });
  return BaseRepository.put(path, data);
}

// Rename search
export function patchSearch(search_id, data = {}) {
  const path = replaceEnvPath(BACKEND_ENDPOINTS.SEARCH.url, { search_id });
  return BaseRepository.patch(path, data);
}

// Delete Search from My Searches
export function deleteSearch(search_id) {
  const path = replaceEnvPath(BACKEND_ENDPOINTS.SEARCH.url, { search_id });
  return BaseRepository.delete(path);
}

export function getSavedSearchNotifications(data = null) {
  const endpoint = BACKEND_ENDPOINTS.USER_SAVE_SEARCH_NOTIFICATIONS;
  const params = convertQueryToGetParams(endpoint, data);
  return BaseRepository.get(endpoint.url, { params, useIdmp: false });
}

export function getSavedSearchNotification(ssn_identifier) {
  const path = replaceEnvPath(BACKEND_ENDPOINTS.USER_SAVE_SEARCH_NOTIFICATION.url, { ssn_identifier });
  return BaseRepository.get(path, { useIdmp: false });
}

export default {
  getSearchesList,
  postSearch,
  getSearch,
  putSearch,
  patchSearch,
  deleteSearch,
  getSavedSearchNotifications,
  getSavedSearchNotification,
};
