import { BaseRepository } from '@/api/baseRepository';
import { replaceEnvPath } from '@/shared/utils';
import { convertQueryToGetParams, convertQueryToJsonRpc } from '@/shared/api';
import enums from "@/config/enums";

const BACKEND_ENDPOINTS = {
  PROJECTS: { url: '/v1/projects' },
  PROJECTS_QUERY: {
    url: '/v1/projects/query',
    filter: {
      id: { type: enums.API_FILTER_TYPES.IN_INT },
      country_id: { type: enums.API_FILTER_TYPES.IN_INT },
      region_id: { type: enums.API_FILTER_TYPES.IN_INT },
      district_id: { type: enums.API_FILTER_TYPES.IN_INT },
      au_path: { type: enums.API_FILTER_TYPES.IN_STRING },
      uncertainty_id: { type: enums.API_FILTER_TYPES.IN_NOT_IN_INT },
      contract_type_id: { type: enums.API_FILTER_TYPES.IN_NOT_IN_INT },
      ecocertified_types: { type: enums.API_FILTER_TYPES.IN_INT },
      certification_type_id: { type: enums.API_FILTER_TYPES.IN_INT },
      stage_id: { type: enums.API_FILTER_TYPES.IN_INT },
      company_roles: { type: enums.API_FILTER_TYPES.IN_NOT_IN_INT },
      materials: { type: enums.API_FILTER_TYPES.IN_NOT_IN_INT },
      companies: { type: enums.API_FILTER_TYPES.IN_INT },
      contacts: { type: enums.API_FILTER_TYPES.IN_INT },
      categories: { type: enums.API_FILTER_TYPES.IN_NOT_IN_INT },
      dev_types: { type: enums.API_FILTER_TYPES.IN_NOT_IN_INT },
      residence_forms: { type: enums.API_FILTER_TYPES.IN_INT },
      start_date: { type: enums.API_FILTER_TYPES.GTE_LTE_DATE },
      finish_date: { type: enums.API_FILTER_TYPES.GTE_LTE_DATE },
      value: { type: enums.API_FILTER_TYPES.GTE_LTE_INT },
      buildings: { type: enums.API_FILTER_TYPES.GTE_LTE_INT },
      apartments: { type: enums.API_FILTER_TYPES.GTE_LTE_INT },
      floors: { type: enums.API_FILTER_TYPES.GTE_LTE_INT },
      underground_floors: { type: enums.API_FILTER_TYPES.GTE_LTE_INT },
      gross_area: { type: enums.API_FILTER_TYPES.GTE_LTE_FLOAT },
      construction_area: { type: enums.API_FILTER_TYPES.GTE_LTE_FLOAT },
      gross_volume: { type: enums.API_FILTER_TYPES.GTE_LTE_FLOAT },
      renovation_area: { type: enums.API_FILTER_TYPES.GTE_LTE_FLOAT },
      demolition_area: { type: enums.API_FILTER_TYPES.GTE_LTE_FLOAT },
      decontamination_area: { type: enums.API_FILTER_TYPES.GTE_LTE_FLOAT },
      usage_area: { type: enums.API_FILTER_TYPES.GTE_LTE_FLOAT },
      source_id: { type: enums.API_FILTER_TYPES.EQ_INT },
      updated_at: { type: enums.API_FILTER_TYPES.GTE_LTE_DATE },
      postcode: { type: enums.API_FILTER_TYPES.GTE_LTE_RANGES },
      latest_update_at: { type: enums.API_FILTER_TYPES.GTE_LTE_DATE },
      contact_company_role_agg: { type: enums.API_FILTER_TYPES.IN_NOT_IN_AGG_STRING },
      company_company_role_agg: { type: enums.API_FILTER_TYPES.IN_NOT_IN_AGG_STRING },
      company_contact_agg: { type: enums.API_FILTER_TYPES.IN_STRING },
      item_created_at: { type: enums.API_FILTER_TYPES.GTE_LTE_DATE },
      item_modified_at: { type: enums.API_FILTER_TYPES.GTE_LTE_DATE },
      version_created_at: { type: enums.API_FILTER_TYPES.GTE_LTE_DATE },
      version_modified_at: { type: enums.API_FILTER_TYPES.GTE_LTE_DATE },
      framework_agreement: { type: enums.API_FILTER_TYPES.EQ_BOOLEAN_NUMBER },
      project_type_id: { type: enums.API_FILTER_TYPES.IN_NOT_IN_INT },
      parish_id: { type: enums.API_FILTER_TYPES.NOT_IN_INT },
      planning_stage_id: { type: enums.API_FILTER_TYPES.IN_NOT_IN_INT },
      company_role_assigned_date: { type: enums.API_FILTER_TYPES.GTE_LTE_DATE },
      crafts: { type: enums.API_FILTER_TYPES.IN_INT },
      ecocertified_type_id: { type: enums.API_FILTER_TYPES.IN_INT },
      developer_company_type_id: { type: enums.API_FILTER_TYPES.IN_INT },
      parking_lots: { type: enums.API_FILTER_TYPES.GTE_LTE_INT },
      building_purpose: { type: enums.API_FILTER_TYPES.IN_INT },
      areas_by_or: { type: enums.API_FILTER_TYPES.GTE_LTE_INT },
      publish_source_id: { type: enums.API_FILTER_TYPES.IN_INT },
      expected_tender_roles: { type: enums.API_FILTER_TYPES.IN_INT },
      planning_approval_date: { type: enums.API_FILTER_TYPES.GTE_LTE_DATE },
    },
  },
  PROJECT: { url: '/v1/projects/{project_id}' },
  PROJECT_LOCKED_REASON: { url: '/v1/projects/{project_id}/lock_reason' },
  TENDERS: { url: '/v1/tenders' },
  TENDERS_QUERY: {
    url: '/v1/tenders/query',
    filter: {
      id: { type: enums.API_FILTER_TYPES.IN_INT },
      tender_subject_id: { type: enums.API_FILTER_TYPES.IN_INT },
      tender_type_id: { type: enums.API_FILTER_TYPES.IN_INT },
      tender_status_id: { type: enums.API_FILTER_TYPES.IN_INT },
      company_role_id: { type: enums.API_FILTER_TYPES.IN_INT },
      framework_agreement: { type: enums.API_FILTER_TYPES.EQ_BOOLEAN_NUMBER },
      first_day_materials_range: { type: enums.API_FILTER_TYPES.GTE_LTE_DATE },
      last_day_materials_range: { type: enums.API_FILTER_TYPES.GTE_LTE_DATE },
      last_day_questions_range: { type: enums.API_FILTER_TYPES.GTE_LTE_DATE },
      last_day_application_range: { type: enums.API_FILTER_TYPES.GTE_LTE_DATE },
      prequalification_date_range: { type: enums.API_FILTER_TYPES.GTE_LTE_DATE },
      country_id: { type: enums.API_FILTER_TYPES.IN_INT },
      region_id: { type: enums.API_FILTER_TYPES.IN_INT },
      district_id: { type: enums.API_FILTER_TYPES.IN_INT },
      au_path: { type: enums.API_FILTER_TYPES.IN_STRING },
      start_date: { type: enums.API_FILTER_TYPES.GTE_LTE_DATE },
      finish_date: { type: enums.API_FILTER_TYPES.GTE_LTE_DATE },
      value: { type: enums.API_FILTER_TYPES.GTE_LTE_INT },
      categories: { type: enums.API_FILTER_TYPES.IN_NOT_IN_INT },
      dev_types: { type: enums.API_FILTER_TYPES.IN_NOT_IN_INT },
      materials: { type: enums.API_FILTER_TYPES.IN_INT },
      source_id: { type: enums.API_FILTER_TYPES.EQ_INT },
      updated_at: { type: enums.API_FILTER_TYPES.GTE_LTE_DATE },
      postcode: { type: enums.API_FILTER_TYPES.GTE_LTE_RANGES },
      latest_update_at: { type: enums.API_FILTER_TYPES.GTE_LTE_DATE },
      item_created_at: { type: enums.API_FILTER_TYPES.GTE_LTE_DATE },
      item_modified_at: { type: enums.API_FILTER_TYPES.GTE_LTE_DATE },
      version_created_at: { type: enums.API_FILTER_TYPES.GTE_LTE_DATE },
      version_modified_at: { type: enums.API_FILTER_TYPES.GTE_LTE_DATE },
      archived: { type: enums.API_FILTER_TYPES.EQ_BOOLEAN_NUMBER },
      parish_id: { type: enums.API_FILTER_TYPES.IN_NOT_IN_INT },
    },
  },
  TENDER: { url: '/v1/tenders/{tender_id}' },
  COMPANIES: { url: '/v1/companies' },
  COMPANIES_AGG_COMPANY_ROLE: { url: '/v1/companies/{company_id}/agg_company_role' },
  COMPANIES_QUERY: {
    url: '/v1/companies/query',
    filter: {
      id: { type: enums.API_FILTER_TYPES.IN_INT },
      company_type_id: { type: enums.API_FILTER_TYPES.IN_NOT_IN_INT },
      country_id: { type: enums.API_FILTER_TYPES.IN_NOT_IN_INT },
      region_id: { type: enums.API_FILTER_TYPES.IN_INT },
      district_id: { type: enums.API_FILTER_TYPES.IN_INT },
      au_path: { type: enums.API_FILTER_TYPES.IN_STRING },
      project_categories: { type: enums.API_FILTER_TYPES.IN_INT },
      project_dev_types: { type: enums.API_FILTER_TYPES.IN_INT },
      project_countries: { type: enums.API_FILTER_TYPES.IN_INT },
      project_company_roles: { type: enums.API_FILTER_TYPES.IN_INT },
      project_start_range: { type: enums.API_FILTER_TYPES.GTE_LTE_DATE },
      project_value_range: { type: enums.API_FILTER_TYPES.GTE_LTE_INT },
      project_count: { type: enums.API_FILTER_TYPES.GTE_LTE_INT },
      contact_count: { type: enums.API_FILTER_TYPES.GTE_LTE_INT },
      contacts: { type: enums.API_FILTER_TYPES.IN_INT },
      active_contacts: { type: enums.API_FILTER_TYPES.IN_INT },
      expired_contacts: { type: enums.API_FILTER_TYPES.IN_INT },
      source_id: { type: enums.API_FILTER_TYPES.EQ_INT },
      updated_at: { type: enums.API_FILTER_TYPES.GTE_LTE_DATE },
      latest_update_at: { type: enums.API_FILTER_TYPES.GTE_LTE_DATE },
      item_created_at: { type: enums.API_FILTER_TYPES.GTE_LTE_DATE },
      item_modified_at: { type: enums.API_FILTER_TYPES.GTE_LTE_DATE },
      version_created_at: { type: enums.API_FILTER_TYPES.GTE_LTE_DATE },
      version_modified_at: { type: enums.API_FILTER_TYPES.GTE_LTE_DATE },
      'projects.id': { type: enums.API_FILTER_TYPES.IN_NOT_IN_INT },
      'projects.categories': { type: enums.API_FILTER_TYPES.IN_NOT_IN_INT },
      'projects.dev_types': { type: enums.API_FILTER_TYPES.IN_NOT_IN_INT },
      'projects.roles': { type: enums.API_FILTER_TYPES.IN_NOT_IN_INT },
      'projects.country_id': { type: enums.API_FILTER_TYPES.IN_INT },
      'projects.region_id': { type: enums.API_FILTER_TYPES.IN_INT },
      'projects.district_id': { type: enums.API_FILTER_TYPES.IN_INT },
      'projects.postcode': { type: enums.API_FILTER_TYPES.GTE_LTE_RANGES },
      'projects.start_date': { type: enums.API_FILTER_TYPES.GTE_LTE_DATE },
      'projects.finish_date': { type: enums.API_FILTER_TYPES.GTE_LTE_DATE },
      'projects.value': { type: enums.API_FILTER_TYPES.GTE_LTE_INT },
      'projects.role_assigned_date': { type: enums.API_FILTER_TYPES.GTE_LTE_DATE },
      'projects.au_path': { type: enums.API_FILTER_TYPES.IN_INT },
      parish_id: { type: enums.API_FILTER_TYPES.IN_NOT_IN_INT },
    },
  },
  COMPANY: { url: '/v1/companies/{company_id}' },
  CONTACTS: { url: '/v1/contacts' },
  CONTACTS_QUERY: {
    url: '/v1/contacts/query',
    filter: {
      id: { type: enums.API_FILTER_TYPES.IN_INT },
      project_count: { type: enums.API_FILTER_TYPES.GTE_LTE_INT },
      company_count: { type: enums.API_FILTER_TYPES.GTE_LTE_INT },
      original_companies: { type: enums.API_FILTER_TYPES.IN_INT },
      project_companies: { type: enums.API_FILTER_TYPES.IN_INT },
      all_companies: { type: enums.API_FILTER_TYPES.IN_INT },
      project_countries: { type: enums.API_FILTER_TYPES.IN_INT },
      project_categories: { type: enums.API_FILTER_TYPES.IN_INT },
      project_dev_types: { type: enums.API_FILTER_TYPES.IN_INT },
      project_company_roles: { type: enums.API_FILTER_TYPES.IN_INT },
      project_contact_roles: { type: enums.API_FILTER_TYPES.IN_INT },
      project_start_range: { type: enums.API_FILTER_TYPES.GTE_LTE_DATE },
      project_value_range: { type: enums.API_FILTER_TYPES.GTE_LTE_INT },
      source_id: { type: enums.API_FILTER_TYPES.EQ_INT },
      updated_at: { type: enums.API_FILTER_TYPES.GTE_LTE_DATE },
      latest_update_at: { type: enums.API_FILTER_TYPES.GTE_LTE_DATE },
      item_created_at: { type: enums.API_FILTER_TYPES.GTE_LTE_DATE },
      item_modified_at: { type: enums.API_FILTER_TYPES.GTE_LTE_DATE },
      version_created_at: { type: enums.API_FILTER_TYPES.GTE_LTE_DATE },
      version_modified_at: { type: enums.API_FILTER_TYPES.GTE_LTE_DATE },
    },
  },
  CONTACT: { url: '/v1/contacts/{contact_id}' },
};

// Get the projects
export function getProjects(data = null) {
  const endpoint = BACKEND_ENDPOINTS.PROJECTS_QUERY;
  const query = convertQueryToJsonRpc(endpoint, data);
  return BaseRepository.post(endpoint.url, query, {
    useIdmp: false,
    query: true,
  });
}

// Get the project by id
export function getProject(project_id) {
  const path = replaceEnvPath(BACKEND_ENDPOINTS.PROJECT.url, { project_id });
  return BaseRepository.get(path, {
    useIdmp: false,
  });
}

// Get the tenders
export function getTenders(data = null) {
  const endpoint = BACKEND_ENDPOINTS.TENDERS_QUERY;
  const query = convertQueryToJsonRpc(endpoint, data);
  return BaseRepository.post(endpoint.url, query, {
    useIdmp: false,
    query: true,
  });
}

// Get the tender by id
export function getTender(tender_id) {
  const path = replaceEnvPath(BACKEND_ENDPOINTS.TENDER.url, { tender_id });
  return BaseRepository.get(path, {
    useIdmp: false,
  });
}

// Get the companies
export function getCompanies(data = null) {
  const endpoint = BACKEND_ENDPOINTS.COMPANIES_QUERY;
  const query = convertQueryToJsonRpc(endpoint, data);
  return BaseRepository.post(endpoint.url, query, {
    useIdmp: false,
    query: true,
  });
}

// Get the company by id
export function getCompany(company_id) {
  const path = replaceEnvPath(BACKEND_ENDPOINTS.COMPANY.url, { company_id });
  return BaseRepository.get(path, {
    useIdmp: false,
  });
}

export function getCompanyAggRoles(company_id) {
  const path = replaceEnvPath(BACKEND_ENDPOINTS.COMPANIES_AGG_COMPANY_ROLE.url, { company_id });
  return BaseRepository.get(path, {
    useIdmp: false,
  });
}

// Get the contacts
export function getContacts(data = null) {
  const endpoint = BACKEND_ENDPOINTS.CONTACTS_QUERY;
  const query = convertQueryToJsonRpc(endpoint, data);
  return BaseRepository.post(endpoint.url, query, {
    useIdmp: false,
    query: true,
  });
}

// Get the contact by id
export function getContact(contact_id) {
  const path = replaceEnvPath(BACKEND_ENDPOINTS.CONTACT.url, { contact_id });
  return BaseRepository.get(path, {
    useIdmp: false,
  });
}

export function getLockedProjectReason(project_id) {
  const endpoint = BACKEND_ENDPOINTS.PROJECT_LOCKED_REASON;
  const path = replaceEnvPath(endpoint.url, { project_id });
  return BaseRepository.get(path);
}

export const LeadsRepository = {
  getProjects,
  getProject,
  getLockedProjectReason,
  getTenders,
  getTender,
  getCompanies,
  getCompany,
  getContacts,
  getContact,
};
