import { BaseRepository } from '@/api/baseRepository';
import { convertQueryToJsonRpc } from '@/shared/api';
import { API_FILTER_TYPES } from "@/config/enums";

const BACKEND_ENDPOINTS = {
  SMART_SELECTIONS_QUERY: {
    url: '/v2/smart_selections/query',
    filter: {
      administrative_units: { type: API_FILTER_TYPES.IN_INT },
      countries: { type: API_FILTER_TYPES.IN_INT },
      categories: { type: API_FILTER_TYPES.IN_INT },
      development_types: { type: API_FILTER_TYPES.IN_INT },
      stages: { type: API_FILTER_TYPES.IN_INT },
      materials: { type: API_FILTER_TYPES.IN_INT },
      planning_stages: { type: API_FILTER_TYPES.IN_INT },
      project_types: { type: API_FILTER_TYPES.IN_INT },
      value_min: { type: API_FILTER_TYPES.GTE_LTE_INT },
      value_max: { type: API_FILTER_TYPES.GTE_LTE_INT },
      currency_id: { type: API_FILTER_TYPES.IN_INT },
      event_roles: { type: API_FILTER_TYPES.IN_INT },
      view_roles: { type: API_FILTER_TYPES.IN_INT },
      tender_event_roles: { type: API_FILTER_TYPES.IN_INT },
      tender_view_roles: { type: API_FILTER_TYPES.IN_INT },
      is_suspended: { type: API_FILTER_TYPES.EQ_BOOLEAN },
      created_at: { type: API_FILTER_TYPES.GTE_LTE_DATE_TIME },
      modified_at: { type: API_FILTER_TYPES.GTE_LTE_DATE_TIME },
      user_profile_id: { type: API_FILTER_TYPES.IN_INT },
      subscription_id: { type: API_FILTER_TYPES.IN_INT },
    },
  },
};

export function getSmartSelections(data = {}) {
  const endpoint = BACKEND_ENDPOINTS.SMART_SELECTIONS_QUERY;
  const query = convertQueryToJsonRpc(endpoint, {
    id_type: 'sharp',
    ...data,
  });
  return BaseRepository.post(endpoint.url, query, {
    useIdmp: false,
    query: true,
  });
}
