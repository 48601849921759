import { BaseRepository } from "@/api/baseRepository"
import { convertQueryToJsonRpc } from "@/shared/api";

const BACKEND_ENDPOINTS = {
  IDMP: { url: '/v1/idmp' },
  // DICT: { url: '/v1/utils/dictionary/get_dict' },
  DICT: { url: '/v1/dict' },
  DICT_VERSIONS: { url: '/v1/dict_versions' },
  TIMEZONES: { url: '/v1/timezones' },
  CLEAR_USER_DATA: { url: '/v1/utils/core/clear_user_data' },
  BATCH: { url: '/v1/batch' },
};

// Generate idempotency key
export function idmp(checkErrors = true) {
  let path = BACKEND_ENDPOINTS.IDMP.url
  return BaseRepository.get(path, {
    useIdmp: false,
    checkErrors,
  })
}

// Returns the dictionary of the specified type, section nad language
export function dict(params) {
  let path = BACKEND_ENDPOINTS.DICT.url
  return BaseRepository.get(path, {
    params: params,
    useOpid: false,
    useIdmp: false
  })
}

// Returns versions for all the sections of entities (vocabularies, lookups & errors)
export function getDictVersions() {
  let path = BACKEND_ENDPOINTS.DICT_VERSIONS.url
  return BaseRepository.get(path, {
    useOpid: false,
    useIdmp: false
  })
}

// Returns the list of system timezones
export function timezones() {
  let path = BACKEND_ENDPOINTS.TIMEZONES.url
  return BaseRepository.get(path, {
    useOpid: false,
    useIdmp: false
  })
}

export function clearUserData() {
  let path = BACKEND_ENDPOINTS.CLEAR_USER_DATA.url
  return BaseRepository.get(path, {
    useIdmp: false
  })
}

export function sendBatch(requests = []) {
  const endpoint = BACKEND_ENDPOINTS.BATCH;
  const data = requests.map(request => ({
    id: request.id,
    method: request.method,
    path: request.path,
    request: convertQueryToJsonRpc(endpoint, request.request),
  }));

  return BaseRepository.post(endpoint.url, {
    data,
  }, {
    useIdmp: false,
  });
}

export default {
  idmp,
  dict,
  getDictVersions,
  timezones,
  clearUserData,
};

