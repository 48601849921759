import Vue from 'vue';
import axios from 'axios';
import axiosRetry from 'axios-retry';
import {
  SERVER_ERROR_CODES,
} from '@/config/enums';
import router from '@/router';
import store from '@/store';
import MiscRepository from './repositories/miscRepository';
import { NotifToastr } from '@/plugins';
import { getGuid, isEmptyValue } from '@/shared/utils';
import { CookiesStorage } from "@/shared/storage/drivers/cookiesStorage";

Vue.use(NotifToastr);

function getRedirectDestination() {
  const { currentRoute } = router;
  const { disableAutoDestination, errorPage } = currentRoute.meta || {};

  if (disableAutoDestination || errorPage) {
    return;
  }

  return encodeURI(currentRoute.fullPath);
}

function showNetworkError() {
  const routeName = 'PageNetworkError';

  if (router.currentRoute.name !== routeName) {
    store.commit('App/SET_NETWORK_OFFLINE', true);
    router.push({
      name: routeName,
      query: {
        destination: getRedirectDestination(),
      },
    });
  }
}

function requestSuccessHandler(result) {
  if (store.state.App.isMaintenanceMode || router.currentRoute.name === 'MaintenanceMode') {
    store.commit('App/SET_MAINTENANCE_MODE', {
      value: false,
    });

    router.push({
      name: 'Home',
    }).catch(() => {});

    return;
  }

  return result;
}

const BaseRepository = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
  useOpid: true,
  useIdmp: true,
  query: false,
  checkErrors: true,
  multipartForm: false,
  opid: null,
});

BaseRepository.interceptors.request.use(
  async (request) => {
    const opid = request.opid || CookiesStorage.get('OPID');
    const maintenanceKey = CookiesStorage.get('Maintenance-Key');

    request.headers['Request-Id'] = getGuid();

    if (maintenanceKey) {
      request.headers['Maintenance-Key'] = maintenanceKey;
    }

    if (request.useOpid && opid) {
      request.headers.Authorization = `Bearer ${opid}`;
    }

    if (request.useIdmp) {
      const idmp = await MiscRepository.idmp();
      request.headers['Idempotency-Key'] = idmp.idempotency_key;
    }

    if (request.query) {
      request.headers['Request-Type'] = 'query';
    }

    if (request.multipartForm) {
      request.headers['Content-Type'] = 'multipart/form-data';
    }

    if (request.anonymToken) {
      request.headers['Anonymous-Token'] = request.anonymToken;
    }

    return request;
  },
  (error) => Promise.reject(error.toString()),
);

BaseRepository.interceptors.response.use(
  async (response) => {
    const { result } = response.data;

    if (!isEmptyValue(result)) {
      return requestSuccessHandler(result);
    }

    const { error } = response.data;

    if (error?.code === SERVER_ERROR_CODES.MAINTENANCE_MODE) {
      store.commit('App/SET_MAINTENANCE_MODE', {
        value: true,
        data: error.data,
      });

      router.push({
        name: 'MaintenanceMode',
      }).catch(() => {
        console.log('BaseRepository MAINTENANCE_MODE error. Error push to MaintenanceMode');
      });

      throw {
        ...error,
        redirected: true,
      };
    }

    if (error?.code === SERVER_ERROR_CODES.ACCEPTED) {
      return error;
    }

    const hasError = !isEmptyValue(error);

    if (!hasError) {
      return;
    }

    const { checkErrors } = response.config;

    if (!checkErrors) {
      throw error;
    }

    let redirected = false;

    switch (error.code) {
      case SERVER_ERROR_CODES.UNAUTHORIZED:
        CookiesStorage.remove('OPID');
        router.push({
          name: 'Logout',
          query: {
            destination: getRedirectDestination(),
          },
        }).catch(() => {});
        redirected = true;
        break;
      case SERVER_ERROR_CODES.COMPANY_SUBSCRIPTION_EXPIRED:
        router.push({
          name: 'PageError',
          query: { titleKey: 'company_subscription_expired', logout: 1 },
        }).catch(() => {});
        redirected = true;
        break;
      case SERVER_ERROR_CODES.USER_LICENSE_EXPIRED:
        router.push({
          name: 'PageError',
          query: { titleKey: 'user_license_expired', logout: 1 },
        }).catch(() => {});
        redirected = true;
        break;
      case SERVER_ERROR_CODES.CLIENT_NOT_ACTIVE:
        router.push({
          name: 'PageError',
          query: { titleKey: 'client_not_active', logout: 1 },
        }).catch(() => {});
        redirected = true;
        break;
      case SERVER_ERROR_CODES.USER_NOT_ACTIVE:
        router.push({
          name: 'PageError',
          query: { titleKey: 'user_not_active', logout: 1 },
        }).catch(() => {});
        redirected = true;
        break;
      case SERVER_ERROR_CODES.USER_SUSPENDED:
        router.push({
          name: 'PageError',
          query: { titleKey: 'user_is_suspended', logout: 1 },
        }).catch(() => {});
        redirected = true;
        break;
      default:
    }

    console.log('API Error', JSON.stringify(response));

    throw {
      ...error,
      redirected,
    };
  },
  (error) => {
    if (store.state.App.networkOffline) {
      return;
    }

    console.log('onReject', error);

    if (!error.response && !navigator.onLine) {
      showNetworkError();
    }

    // if (error.response) {
    //   // Request made and server responded
    //   console.log(error.response.data);
    //   console.log(error.response.status);
    //   console.log(error.response.headers);
    // } else if (error.request) {
    //   // The request was made but no response was received
    //   console.log(error.request);
    // } else {
    //   // Something happened in setting up the request that triggered an Error
    //   console.log('Error', error.message);
    // }

    console.log('Response rejected error:', error);
    return Promise.reject(error.toString());
  },
);

axiosRetry(BaseRepository, { retries: process.env.VUE_APP_AXIOS_RETRY, retryCondition: () => true });

export {
  BaseRepository,
};
