import { BaseRepository } from '@/api/baseRepository';
import { replaceEnvPath } from '@/shared/utils';
import { convertQueryToGetParams, convertQueryToJsonRpc } from '@/shared/api';
import enums from "@/config/enums";

const BACKEND_ENDPOINTS = {
  PM_CLIENTS: {
    url: '/v1/myran/pm_clients',
    filter: {
      id: { type: enums.API_FILTER_TYPES.IN_INT },
      hint: { type: enums.API_FILTER_TYPES.Q_STRING },
      client_id: { type: enums.API_FILTER_TYPES.EQ_INT },
      created_at: { type: enums.API_FILTER_TYPES.GTE_LTE_DATE_TIME },
    },
  },
  PM_CLIENT_VERSIONS: {
    url: '/v1/myran/pm_client_versions',
    filter: {
      id: { type: enums.API_FILTER_TYPES.IN_INT },
      pm_client_id: { type: enums.API_FILTER_TYPES.EQ_INT },
      created_at: { type: enums.API_FILTER_TYPES.GTE_LTE_DATE_TIME },
      is_fetched: { type: enums.API_FILTER_TYPES.EQ_INT },
      is_valid: { type: enums.API_FILTER_TYPES.EQ_INT },
      is_migrated: { type: enums.API_FILTER_TYPES.EQ_INT },
      fetched_at: { type: enums.API_FILTER_TYPES.GTE_LTE_DATE_TIME },
      validated_at: { type: enums.API_FILTER_TYPES.GTE_LTE_DATE_TIME },
      migrated_at: { type: enums.API_FILTER_TYPES.GTE_LTE_DATE_TIME },
      fetching_launched_at: { type: enums.API_FILTER_TYPES.GTE_LTE_DATE_TIME },
      validation_launched_at: { type: enums.API_FILTER_TYPES.GTE_LTE_DATE_TIME },
      migration_launched_at: { type: enums.API_FILTER_TYPES.GTE_LTE_DATE_TIME },
    },
  },
  PM_CLIENT_VERSION: { url: '/v1/myran/pm_client_versions/{pm_client_version_id}' },
  PM_CLIENT_VERSION_FETCH: { url: '/v1/myran/pm_client_versions/{pm_client_version_id}/fetch' },
  PM_CLIENT_VERSION_VALIDATE: { url: '/v1/myran/pm_client_versions/{pm_client_version_id}/validate' },
  PM_CLIENT_VERSION_MIGRATE: { url: '/v1/myran/pm_client_versions/{pm_client_version_id}/migrate' },
  PM_CLIENT_VERSION_MIGRATE_FORCE: { url: '/v1/myran/pm_client_versions/{pm_client_version_id}/migrate?force=1' },
  PM_PROJECTS: {
    url: '/v1/myran/pm_projects',
    filter: {
      id: { type: enums.API_FILTER_TYPES.IN_INT },
      pm_client_version_id: { type: enums.API_FILTER_TYPES.EQ_INT },
      pm_client_id: { type: enums.API_FILTER_TYPES.EQ_INT },
      source_id: { type: enums.API_FILTER_TYPES.EQ_INT },
      parabyg_id: { type: enums.API_FILTER_TYPES.EQ_INT },
      rsm_id: { type: enums.API_FILTER_TYPES.EQ_INT },
      entity_id: { type: enums.API_FILTER_TYPES.EQ_INT },
      is_valid: { type: enums.API_FILTER_TYPES.EQ_INT },
      is_migrated: { type: enums.API_FILTER_TYPES.EQ_INT },
    },
  },
  PM_COMPANIES: {
    url: '/v1/myran/pm_companies',
    filter: {
      id: { type: enums.API_FILTER_TYPES.IN_INT },
      pm_client_version_id: { type: enums.API_FILTER_TYPES.EQ_INT },
      source_id: { type: enums.API_FILTER_TYPES.EQ_INT },
      parabyg_id: { type: enums.API_FILTER_TYPES.EQ_INT },
      rsm_id: { type: enums.API_FILTER_TYPES.EQ_INT },
      entity_id: { type: enums.API_FILTER_TYPES.EQ_INT },
      is_valid: { type: enums.API_FILTER_TYPES.EQ_INT },
      is_migrated: { type: enums.API_FILTER_TYPES.EQ_INT },
    },
  },
  PM_CONTACTS: {
    url: '/v1/myran/pm_contacts',
    filter: {
      id: { type: enums.API_FILTER_TYPES.IN_INT },
      pm_client_version_id: { type: enums.API_FILTER_TYPES.EQ_INT },
      source_id: { type: enums.API_FILTER_TYPES.EQ_INT },
      parabyg_id: { type: enums.API_FILTER_TYPES.EQ_INT },
      rsm_id: { type: enums.API_FILTER_TYPES.EQ_INT },
      entity_id: { type: enums.API_FILTER_TYPES.EQ_INT },
      is_valid: { type: enums.API_FILTER_TYPES.EQ_INT },
      is_migrated: { type: enums.API_FILTER_TYPES.EQ_INT },
    },
  },
  PM_EMPLOYEES: {
    url: '/v1/myran/pm_employees',
    filter: {
      id: { type: enums.API_FILTER_TYPES.IN_INT },
      pm_client_version_id: { type: enums.API_FILTER_TYPES.EQ_INT },
      source_id: { type: enums.API_FILTER_TYPES.EQ_INT },
      is_valid: { type: enums.API_FILTER_TYPES.EQ_INT },
      email: { type: enums.API_FILTER_TYPES.Q_STRING },
      active: { type: enums.API_FILTER_TYPES.EQ_INT },
    },
  },
  PM_DOCUMENTS: {
    url: '/v1/myran/pm_documents',
    filter: {
      id: { type: enums.API_FILTER_TYPES.IN_INT },
      pm_client_version_id: { type: enums.API_FILTER_TYPES.EQ_INT },
      source_id: { type: enums.API_FILTER_TYPES.EQ_INT },
      is_valid: { type: enums.API_FILTER_TYPES.EQ_INT },
    },
  },
  PM_ACTIVITIES: {
    url: '/v1/myran/pm_activities',
    filter: {
      id: { type: enums.API_FILTER_TYPES.IN_INT },
      pm_client_version_id: { type: enums.API_FILTER_TYPES.EQ_INT },
      source_id: { type: enums.API_FILTER_TYPES.EQ_INT },
      is_valid: { type: enums.API_FILTER_TYPES.EQ_INT },
      is_migrated: { type: enums.API_FILTER_TYPES.EQ_INT },
    },
  },
  PM_CUSTOM_FIELDS: {
    url: '/v1/myran/pm_custom_fields',
    filter: {
      id: { type: enums.API_FILTER_TYPES.IN_INT },
      pm_client_version_id: { type: enums.API_FILTER_TYPES.EQ_INT },
      value_type: { type: enums.API_FILTER_TYPES.EQ_STRING },
      hint: { type: enums.API_FILTER_TYPES.Q_STRING },
      custom_field_id: { type: enums.API_FILTER_TYPES.EQ_INT },
      is_valid: { type: enums.API_FILTER_TYPES.EQ_INT },
    },
  },
  PM_CUSTOM_FIELDS_AGGREGATIONS: { url: '/v1/myran/pm_custom_fields/aggregations' },
  PM_CUSTOM_FIELD_VALUES: {
    url: '/v1/myran/pm_custom_field_values',
    filter: {
      id: { type: enums.API_FILTER_TYPES.IN_INT },
      pm_client_version_id: { type: enums.API_FILTER_TYPES.EQ_INT },
      is_valid: { type: enums.API_FILTER_TYPES.EQ_INT },
      is_migrated: { type: enums.API_FILTER_TYPES.EQ_INT },
      pm_custom_field_id: { type: enums.API_FILTER_TYPES.EQ_INT },
      pm_entity_type_id: { type: enums.API_FILTER_TYPES.EQ_INT },
      pm_entity_id: { type: enums.API_FILTER_TYPES.IN_INT },
      entity_id: { type: enums.API_FILTER_TYPES.IN_INT },
      custom_field_id: { type: enums.API_FILTER_TYPES.EQ_INT },
      display_name: { type: enums.API_FILTER_TYPES.Q_STRING },
      value_type: { type: enums.API_FILTER_TYPES.EQ_STRING },
      hint: { type: enums.API_FILTER_TYPES.Q_STRING },
      value_as_string: { type: enums.API_FILTER_TYPES.Q_STRING },
      reference_as_string: { type: enums.API_FILTER_TYPES.Q_STRING },
      reference_source: { type: enums.API_FILTER_TYPES.Q_STRING },
    },
  },
  PM_CUSTOM_FIELD_SELECTIONS: {
    url: '/v1/myran/pm_custom_field_selections',
    filter: {
      id: { type: enums.API_FILTER_TYPES.IN_INT },
      pm_client_version_id: { type: enums.API_FILTER_TYPES.EQ_INT },
      pm_custom_field_id: { type: enums.API_FILTER_TYPES.EQ_INT },
      value: { type: enums.API_FILTER_TYPES.Q_STRING },
      custom_field_selection_key: { type: enums.API_FILTER_TYPES.Q_STRING },
      is_valid: { type: enums.API_FILTER_TYPES.EQ_INT },
    },
  },
  PM_CUSTOM_FIELD_EMPLOYEES: {
    url: '/v1/myran/pm_custom_field_employees',
    filter: {
      id: { type: enums.API_FILTER_TYPES.IN_INT },
      pm_client_version_id: { type: enums.API_FILTER_TYPES.EQ_INT },
      pm_custom_field_id: { type: enums.API_FILTER_TYPES.EQ_INT },
      pm_employee_id: { type: enums.API_FILTER_TYPES.EQ_INT },
      source_id: { type: enums.API_FILTER_TYPES.EQ_INT },
      email: { type: enums.API_FILTER_TYPES.Q_STRING },
      active: { type: enums.API_FILTER_TYPES.EQ_INT },
      is_valid: { type: enums.API_FILTER_TYPES.EQ_INT },
      is_migrated: { type: enums.API_FILTER_TYPES.EQ_INT },
    },
  },
};

export function getPMClients(data = null) {
  const endpoint = BACKEND_ENDPOINTS.PM_CLIENTS;
  const params = convertQueryToGetParams(endpoint, data);
  return BaseRepository.get(endpoint.url, {
    params,
    useIdmp: false,
  });
}

export function postPMClient(data = {}) {
  const path = BACKEND_ENDPOINTS.PM_CLIENTS.url;
  return BaseRepository.post(path, data);
}

export function getPMClientVersions(data = null) {
  const endpoint = BACKEND_ENDPOINTS.PM_CLIENT_VERSIONS;
  const params = convertQueryToGetParams(endpoint, data);
  return BaseRepository.get(endpoint.url, {
    params,
    useIdmp: false,
  });
}

export function postPMClientVersion(data = {}) {
  const path = BACKEND_ENDPOINTS.PM_CLIENT_VERSIONS.url;
  return BaseRepository.post(path, data);
}

export function deletePMClientVersion(pm_client_version_id) {
  const path = replaceEnvPath(BACKEND_ENDPOINTS.PM_CLIENT_VERSION.url, { pm_client_version_id });
  return BaseRepository.delete(path);
}

export function getPMClientVersion(pm_client_version_id) {
  const path = replaceEnvPath(BACKEND_ENDPOINTS.PM_CLIENT_VERSION.url, { pm_client_version_id });
  return BaseRepository.get(path, {
    useIdmp: false,
  });
}

export function fetchPMClientVersion(pm_client_version_id, data = {}) {
  const path = replaceEnvPath(BACKEND_ENDPOINTS.PM_CLIENT_VERSION_FETCH.url, { pm_client_version_id });
  return BaseRepository.put(path, data);
}

export function validatePMClientVersion(pm_client_version_id, data = {}) {
  const path = replaceEnvPath(BACKEND_ENDPOINTS.PM_CLIENT_VERSION_VALIDATE.url, { pm_client_version_id });
  return BaseRepository.put(path, data);
}

export function migratePMClientVersion(pm_client_version_id, data = {}) {
  const path = replaceEnvPath(BACKEND_ENDPOINTS.PM_CLIENT_VERSION_MIGRATE.url, { pm_client_version_id });
  return BaseRepository.put(path, data);
}

export function forceMigratePMClientVersion(pm_client_version_id, data = {}) {
  const path = replaceEnvPath(BACKEND_ENDPOINTS.PM_CLIENT_VERSION_MIGRATE_FORCE.url, { pm_client_version_id });
  return BaseRepository.put(path, data);
}

export function getPMProjects(data = null) {
  const endpoint = BACKEND_ENDPOINTS.PM_PROJECTS;
  const params = convertQueryToGetParams(endpoint, data);
  return BaseRepository.get(endpoint.url, {
    params,
    useIdmp: false,
  });
}

export function getPMCompanies(data = null) {
  const endpoint = BACKEND_ENDPOINTS.PM_COMPANIES;
  const params = convertQueryToGetParams(endpoint, data);
  return BaseRepository.get(endpoint.url, {
    params,
    useIdmp: false,
  });
}

export function getPMContacts(data = null) {
  const endpoint = BACKEND_ENDPOINTS.PM_CONTACTS;
  const params = convertQueryToGetParams(endpoint, data);
  return BaseRepository.get(endpoint.url, {
    params,
    useIdmp: false,
  });
}

export function getPMActivities(data = null) {
  const endpoint = BACKEND_ENDPOINTS.PM_ACTIVITIES;
  const params = convertQueryToGetParams(endpoint, data);
  return BaseRepository.get(endpoint.url, {
    params,
    useIdmp: false,
  });
}

export function getPMDocuments(data = null) {
  const endpoint = BACKEND_ENDPOINTS.PM_DOCUMENTS;
  const params = convertQueryToGetParams(endpoint, data);
  return BaseRepository.get(endpoint.url, {
    params,
    useIdmp: false,
  });
}

export function getPMEmployees(data = null) {
  const endpoint = BACKEND_ENDPOINTS.PM_EMPLOYEES;
  const params = convertQueryToGetParams(endpoint, data);
  return BaseRepository.get(endpoint.url, {
    params,
    useIdmp: false,
  });
}

export function getPMCustomFields(data = null) {
  const endpoint = BACKEND_ENDPOINTS.PM_CUSTOM_FIELDS;
  const params = convertQueryToGetParams(endpoint, data);
  return BaseRepository.get(endpoint.url, {
    params,
    useIdmp: false,
  });
}

export function getPMCustomFieldValues(data = null) {
  const endpoint = BACKEND_ENDPOINTS.PM_CUSTOM_FIELD_VALUES;
  const params = convertQueryToGetParams(endpoint, data);
  return BaseRepository.get(endpoint.url, {
    params,
    useIdmp: false,
  });
}

export function getPMCustomFieldSelections(data = null) {
  const endpoint = BACKEND_ENDPOINTS.PM_CUSTOM_FIELD_SELECTIONS;
  const params = convertQueryToGetParams(endpoint, data);
  return BaseRepository.get(endpoint.url, {
    params,
    useIdmp: false,
  });
}

export function getPMCustomFieldEmployees(data = null) {
  const endpoint = BACKEND_ENDPOINTS.PM_CUSTOM_FIELD_EMPLOYEES;
  const params = convertQueryToGetParams(endpoint, data);
  return BaseRepository.get(endpoint.url, {
    params,
    useIdmp: false,
  });
}

export function getPMCustomFieldsAggregations(data = {}) {
  const endpoint = BACKEND_ENDPOINTS.PM_CUSTOM_FIELDS_AGGREGATIONS;
  const params = convertQueryToGetParams(endpoint, data);
  return BaseRepository.get(endpoint.url, {
    params,
    useIdmp: false,
  });
}
