import enums from "@/config/enums";
import { convertQueryToGetParams } from "@/shared/api";
import { BaseRepository } from "@/api/baseRepository";
import { replaceEnvPath } from "@/shared/utils";

const BACKEND_ENDPOINTS = {
  DEAD_MESSAGES: {
    url: '/v1/dictionaries/dead_messages',
    filter: {
      id: { type: enums.API_FILTER_TYPES.IN_INT },
      service_name: { type: enums.API_FILTER_TYPES.Q_STRING },
      topic: { type: enums.API_FILTER_TYPES.Q_STRING },
      schema_name: { type: enums.API_FILTER_TYPES.Q_STRING },
      idempotency_key: { type: enums.API_FILTER_TYPES.Q_STRING },
      request_id: { type: enums.API_FILTER_TYPES.Q_STRING },
      created_at: { type: enums.API_FILTER_TYPES.GTE_LTE_DATE_TIME },
      failed_at: { type: enums.API_FILTER_TYPES.GTE_LTE_DATE_TIME },
      resent_at: { type: enums.API_FILTER_TYPES.GTE_LTE_DATE_TIME },
      payload: { type: enums.API_FILTER_TYPES.Q_STRING },
      reason: { type: enums.API_FILTER_TYPES.Q_STRING },
    },
  },
  DEAD_MESSAGE: { url: '/v1/dictionaries/dead_messages/{dead_message_id}' },
  DEAD_MESSAGE_RESEND: { url: '/v1/dictionaries/dead_messages/{dead_message_id}/resend' },

  //
  DEAD_MESSAGE_SUBSCRIPTIONS: {
    url: '/v1/dictionaries/dead_message_subscriptions',
    filter: {
      id: { type: enums.API_FILTER_TYPES.IN_INT },
      email: { type: enums.API_FILTER_TYPES.Q_STRING },
      service_name: { type: enums.API_FILTER_TYPES.Q_STRING },
      topic: { type: enums.API_FILTER_TYPES.Q_STRING },
    },
  },
  DEAD_MESSAGE_SUBSCRIPTION: { url: '/v1/dictionaries/dead_message_subscriptions/{dead_message_subscription_id}' },

  ERROR_MESSAGES: {
    url: '/v1/dictionaries/error_messages',
    filter: {
      id: { type: enums.API_FILTER_TYPES.IN_INT },
      error_code: { type: enums.API_FILTER_TYPES.Q_STRING },
      error_message: { type: enums.API_FILTER_TYPES.Q_STRING },
      service_name: { type: enums.API_FILTER_TYPES.Q_STRING },
    }
  },
  DICT_DOMAINS: { url: '/v1/dictionaries/domains' },
  DICT_DOMAIN: { url: '/v1/dictionaries/domains/{domain_id}' },
};

// Dead messages
export function getDeadMessages(data = null) {
  const endpoint = BACKEND_ENDPOINTS.DEAD_MESSAGES;
  const params = convertQueryToGetParams(endpoint, data);
  return BaseRepository.get(endpoint.url, {
    params,
    useIdmp: false,
  });
}

export function getDeadMessage(dead_message_id) {
  const path = replaceEnvPath(BACKEND_ENDPOINTS.DEAD_MESSAGE.url, { dead_message_id });
  return BaseRepository.get(path, {
    useIdmp: false,
  });
}

export function patchDeadMessage(dead_message_id, data = {}) {
  const path = replaceEnvPath(BACKEND_ENDPOINTS.DEAD_MESSAGE.url, { dead_message_id });
  return BaseRepository.patch(path, data);
}

export function deleteDeadMessage(dead_message_id) {
  const path = replaceEnvPath(BACKEND_ENDPOINTS.DEAD_MESSAGE.url, { dead_message_id });
  return BaseRepository.delete(path);
}

export function resendDeadMessage(dead_message_id, data = {}) {
  const path = replaceEnvPath(BACKEND_ENDPOINTS.DEAD_MESSAGE_RESEND.url, { dead_message_id });
  return BaseRepository.put(path, data);
}

// Dead messages subscriptions
export function getDeadMessagesSubscriptions(data = null) {
  const endpoint = BACKEND_ENDPOINTS.DEAD_MESSAGE_SUBSCRIPTIONS;
  const params = convertQueryToGetParams(endpoint, data);
  return BaseRepository.get(endpoint.url, {
    params,
    useIdmp: false,
  });
}

export function postDeadMessageSubscription(data = {}) {
  const path = BACKEND_ENDPOINTS.DEAD_MESSAGE_SUBSCRIPTIONS.url;
  return BaseRepository.post(path, data);
}

export function getDeadMessageSubscription(dead_message_subscription_id) {
  const path = replaceEnvPath(BACKEND_ENDPOINTS.DEAD_MESSAGE_SUBSCRIPTION.url, { dead_message_subscription_id });
  return BaseRepository.get(path, {
    useIdmp: false,
  });
}

export function patchDeadMessageSubscription(dead_message_subscription_id, data = {}) {
  const path = replaceEnvPath(BACKEND_ENDPOINTS.DEAD_MESSAGE_SUBSCRIPTION.url, { dead_message_subscription_id });
  return BaseRepository.patch(path, data);
}

export function deleteDeadMessageSubscription(dead_message_subscription_id) {
  const path = replaceEnvPath(BACKEND_ENDPOINTS.DEAD_MESSAGE_SUBSCRIPTION.url, { dead_message_subscription_id });
  return BaseRepository.delete(path);
}

// Error messages
export function getErrorMessages(data = null) {
  const endpoint = BACKEND_ENDPOINTS.ERROR_MESSAGES;
  const params = convertQueryToGetParams(endpoint, data);
  return BaseRepository.get(endpoint.url, {
    params,
    useIdmp: false,
  });
}

export function getDomains(data = null) {
  const endpoint = BACKEND_ENDPOINTS.DICT_DOMAINS;
  const params = convertQueryToGetParams(endpoint, data);
  return BaseRepository.get(endpoint.url, {
    params,
    useIdmp: false,
  });
}

export function getDomain(domain_id) {
  const path = replaceEnvPath(BACKEND_ENDPOINTS.DICT_DOMAIN.url, { domain_id });
  return BaseRepository.get(path, {
    useIdmp: false,
  });
}
