import { BaseRepository } from '@/api/baseRepository';
import { replaceEnvPath } from '@/shared/utils';

const BACKEND_ENDPOINTS = {
  USER_FIELDSETS: { url: '/v1/me/fieldsets/{list_type_id}' },
};

// Get the fieldset of the specified type (project, tenders, companies and contacts)
export function getFieldsets(list_type_id) {
  const path = replaceEnvPath(BACKEND_ENDPOINTS.USER_FIELDSETS.url, { list_type_id });
  return BaseRepository.get(path, {
    useIdmp: false,
  });
}

// Update fieldset
export function putFieldsets(list_type_id, data = {}) {
  const path = replaceEnvPath(BACKEND_ENDPOINTS.USER_FIELDSETS.url, { list_type_id });
  return BaseRepository.put(path, data);
}

// Reset fieldset
export function deleteFieldsets(list_type_id) {
  const path = replaceEnvPath(BACKEND_ENDPOINTS.USER_FIELDSETS.url, { list_type_id });
  return BaseRepository.delete(path);
}

export default {
  getFieldsets,
  putFieldsets,
  deleteFieldsets,
};
