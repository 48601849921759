import { BaseRepository } from '@/api/baseRepository';
import { replaceEnvPath } from '@/shared/utils';
import { convertQueryToJsonRpc } from '@/shared/api';
import {
  API_FILTER_TYPES,
} from '@/config/enums';

const BACKEND_ENDPOINTS = {
  LICENSES_QUERY: {
    url: '/v2/licenses/query',
    filter: {
      id: { type: API_FILTER_TYPES.IN_INT },
    },
  },
  LICENSES: { url: '/v2/licenses' },
  LICENSE: { url: '/v2/licenses/{license_id}' },
};

// Get licenses
export function getLicenses(data = null) {
  const endpoint = BACKEND_ENDPOINTS.LICENSES_QUERY;
  const query = convertQueryToJsonRpc(endpoint, data);
  return BaseRepository.post(endpoint.url, query, {
    useIdmp: false,
    query: true,
  });
}

export function getLicense(license_id) {
  const path = replaceEnvPath(BACKEND_ENDPOINTS.LICENSE.url, { license_id });
  return BaseRepository.get(path, {
    useIdmp: false,
  });
}

export function patchLicense(license_id, data = {}) {
  const path = replaceEnvPath(BACKEND_ENDPOINTS.LICENSE.url, { license_id });
  return BaseRepository.patch(path, data);
}
